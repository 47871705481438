

import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react';




export default class ToCustomComponent extends Component {

    constructor(props) {
        super(props)
        this.status = props.status;
        

        this.label = 'خيارات أخرى';






        this.state = {

            showLife: false,


        }
    }

    toggleLife = (event) => {
        event.preventDefault();
        this.setState({ showLife: !this.state.showLife })
    }



    render() {



        return (
            <div>

                <div className='form-group col-12'>
                    {this.labelCaption}

                    <button
                        type={this.type}
                        className={'form-control '}
                        itemID={this.itemID}
                        placeholder={this.placeholder}

                        onClick={this.toggleLife}
     

                    >{this.buttonLabel} </button>
                </div>

                <Modal
                    // dimmer={'blurring'}
                    open={this.state.showLife}

                    onClose={this.toggleMap}>
                    <Modal.Header> {this.label}
                        <div style={{ float: 'left' }}>
                            <Modal.Actions style={{ textAlign: 'center' }}>

                                <Button color='black' onClick={this.toggleLife}>
                                    <span>تراجع</span>
                                </Button>
                            </Modal.Actions>

                        </div>
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <section>
                            <div>
                             
                            </div>
                        </section>

                    </Modal.Content>

                </Modal>
            </div>
        )
    }
}





