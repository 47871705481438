import React from 'react';
import {  Popup } from 'semantic-ui-react'
import { connect } from "react-redux";
import Base from '../pro/Base';
import { Link } from 'react-router-dom'
import LogOut from './LogOut';

export const SubMenuLink = (props) => {
    let position = "right center";
    if (props.lang === 'ar') {
        position = "left center";
    }


    let icon = ''
    // if(props.icon!=='' && props.icon!=='-' && props.icon!=='null')
    //  icon = <Icon name={'fas fa-shopping-basket '+props.icon} />

     icon = <i className={`fas fa-${props.icon}`}></i>






    return (
        <Popup
            content={props.title}
            position={position}

            trigger={
                <li >

                    <Link
                        to={`${props.to}`}
                        style={{ width: "100%", display: "block" }}
                    // onMouseDown={() =>  false}
                    // onClick={() => {
                    //     History.replace(`${props.to}?${props.langQuery}`, {user: hash})
                    //     // window.location.hash = 'hash'
                    // }}
                    >
                        {icon}
                        <span style={{ margin: 14 }}>{props.title}</span> </Link>
                </li>
            } />
    );

}


class SubMenu extends Base {
    constructor(props) {
        super(props)
        this.error = false;
    }
    render() {
        let { links } = this.props;
        links = links.map((link, i) => <SubMenuLink index={i + 1} langQuery={this.props.langQuery} {...link} key={i} lang={this.props.lang} />)
        return (
            <div>
                {links}
                <LogOut />
            </div>
        );
    }
}
export class SlideMenuLink extends Base {
    constructor(props) {
        super(props)
        this.error = false;
    }
    render() {
        return (
            <li>
                <i className={"fal " + this.props.icon}></i> <span>{this.props.title}</span>
            </li>
        );
    }
}


export const SlideMenu = (props) => {
    let { links } = props;
    links = links.map((link, i) => <SlideMenuLink {...link} key={i} />)
    return (
        <div>
            {links}
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        lang: state.app.lang,
        user: state.auth.user,
    }
}
export default connect(mapStateToProps, {})(SubMenu);
