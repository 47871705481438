import React from 'react'
import UploadImage from './upload/UploadImage';
import { Label, Icon } from 'semantic-ui-react';
import Base from '../../pro/Base';

export default class ImageInput extends Base {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            imagesIds: [],
            images: [],
        }

        this.label = props.label
    }
    onRemoveImage = (index) => {
        const { imagesIds, images } = this.state
        images.splice(index, 1);
        this.changeState("images", images)
        imagesIds.splice(index, 1);
        this.changeState("imagesIds", imagesIds)
    }
    onUploadFinish = (url) => {
        this.changeState("imagesIds", [...this.state.imagesIds, url])
        this.props.onImageSelect(url);

    }
    onSetImage = (event) => {
        let images = this.state.images;
        images.push(event.target.files[0]);
        this.setState({
            images
        })
    }
    render() {
        return (
            <>
                <div className="rows">

                    <div className="rows" >
                        <div className="col-lg-12">
                            <Label className="mr-sm-3" pointing="left">{this.label}</Label>

                            <div className="btn btn-default  custom-file-uploader"
                                style={{ margin: "0px 10px" }}
                            >
                                <input type="file" name="file/"
                                    disabled={!!this.state.images[0]}
                                    onChange={this.onSetImage}
                                    style={{ position: "absolute", opacity: 0 }}
                                />
                                <Icon name="images" style={{ color: "#0758ef" }} />
                            </div>
                        </div>
                    </div>

                </div>

                <div className=" image-listt my-3  mx-sm-3">
                    {
                        this.state.images.map((img, i) => {
                            return <UploadImage
                                key={i}
                                image={img}
                                onRemoveImage={() => this.onRemoveImage(i)}
                                onUploadFinish={(attachId) => this.onUploadFinish(attachId)}
                            />
                        })
                    }
                </div>

            </>
        )
    }
}
