
     import React from 'react'
import { connect } from 'react-redux'
import Table from '../../component/grid/Table';


import Route from '../../pro/Route';
import Connect from '../../component/Connect';

// import { setOnNotification } from '../states/actions/loginActions';

class Urls extends Route {
  constructor(props) {
    super(props)

    this.page = 'H-5505';
    this.tableName = 'H-5505';
    this.queryString2 = this.queryString();                         
let tables ='';
    if (props.tables)
    tables = props.tables.fields;
    let conditions ={};
    let foreginKey ={};
    if (props.tables)
     tables.forEach(element => {

       if(element.type==='fromQuery' || element.setFilter === 3 || element.setFilter === 2)
         {
         if(this.queryString2[element.itemID])
            conditions[element.itemID]= this.queryString2[element.itemID];
            if(!this.queryString2[element.itemID] && element.type==='fromQuery')
            conditions[element.itemID]= 0;

          

         }
         if(element.type==='fromQuery'){
            if(this.queryString2[element.itemID])
                 foreginKey[element.itemID]= this.queryString2[element.itemID];
                 else
                 foreginKey[element.itemID]= 0
         }

         
        });
        conditions.pageNo=this.queryString2.pageNo
        this.conditions=conditions
        this.foreginKey=foreginKey
        


    this.state = {
      ...this.state,
      c_status: 'connecting',
      url: '',
      visible: false,
      isLoading:false,
    }
    this.cut = 'id,deleted';
 
    this.cut = this.cut.split(',')
  }
  async componentDidMount() {
    super.componentDidMount();
    // this.get()
    this.setState({isLoading: true})
    await this.findAll();
    this.setState({isLoading: false})
  }
  async UNSAFE_componentWillReceiveProps(Props) {
 

    this.setState({isLoading: true})
 this.queryString2 = this.queryString3(Props.location.search);


    let tables =this.props.tables.fields;
    let conditions ={};
    let foreginKey ={};
     tables.forEach(element => {

       if(element.type==='fromQuery' || element.setFilter === 3 || element.setFilter === 2)
         {
        if(this.queryString2[element.itemID])
          conditions[element.itemID]= this.queryString2[element.itemID];
          if(!this.queryString2[element.itemID] && element.type==='fromQuery')
          conditions[element.itemID]= 0;

        

       }
       if(element.type==='fromQuery'){
          if(this.queryString2[element.itemID])
               foreginKey[element.itemID]= this.queryString2[element.itemID];
               else
               foreginKey[element.itemID]= 0
       }
        });

        conditions.pageNo=this.queryString2.pageNo
        this.conditions=conditions
        this.foreginKey=foreginKey
        await this.findAll();
  this.setState({isLoading:false})
  }
  render() {

    let q = this.props.location.search;
    let a =q;
    q=a;
    
    let pagePath = ''
    let count =0
    let rowOfPage =0
    let custCount ={}
    let rowsTotals ={}
    let pageTotals ={}
    let controls ={}
    let total =[]
    
    if (this.state.tableDetails){
      
    
    pagePath = this.state.tableDetails.pagePath;


    count = this.state.tableDetails.count;
    rowOfPage = this.state.tableDetails.rowOfPage;
    custCount = this.state.tableDetails.custCount;
    pageTotals = this.state.tableDetails.pageTotals;
    rowsTotals = this.state.tableDetails.rowsTotals;
    controls = this.state.tableDetails.controls
    total = this.state.tableDetails.total
    
    
    }

    sessionStorage.setItem('page-urls','urls');
    sessionStorage.setItem('name-urls','محفظة الروابط');
    return (
      <Connect parent={this}>
      {
        this.state.isLoading?
        <this.Loading/>
      :

    <Table
    
    list={this.state.table}
    parent={this}
    cut={this.cut}
    page={this.page}
    
    pk='id'
    pagePath={pagePath}
    
    
    modelDet ={1}
    
    count = {count}
    custCount  = {custCount}
    rowOfPage  = {rowOfPage}
    rowsTotals = {rowsTotals}
    pageTotals = {pageTotals}
    query={this.props.location.search}


    actions={{ delete: controls.delete, edit:controls.edit }}
    total ={total}
    addNew={{ page: this.page, type: '' , foreginKey: this.foreginKey}}

    setAddBt={controls.newRow}
    // setAddBt={true}
    />
    
      }
      </Connect>
    )

  }
}





const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    accessToken: state.auth.accessToken,
    tables: state.app.tableDetails.tables['H-5505'],
    user: state.auth.user,
  }
}
export default connect(mapStateToProps, {})(Urls);

