import React from "react";

class Footer extends React.Component
{
    
    
    render()
    {
        return (
            <footer className="py-5 bg-dark" >
                footer
            </footer>
        );
    }
}

export default Footer;