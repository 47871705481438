import  { Component } from 'react'

import AppDetails from '../../package.json';

import routes from './routes';

export default class Pager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            links: [],
        }
    }
    componentDidMount() {
        this.setPages();

    }
    setPages = () => {
        let pathname = window.location.pathname
        let pathArray = pathname.replace(/\/$ "/, '').split("/");
        let homepage = AppDetails.homepage
        homepage = homepage.replace(/[\\/]/, '').toLowerCase();
        let firstUrl = pathArray[1].toLowerCase();

        let links = [];
        if (homepage ===firstUrl) {
            // pathArray[1] = "000";
            pathArray.splice(1, 1)
        }
        for (let index = 0; index < pathArray.length; index++) {
            const url = pathArray[index];
            if (pathArray[1] ==="") {
                links.push(routes.home);
                break
            } else {
                if (url ==="") {
                    links.push(routes.home);
                } else if (routes[url]) {
                    let element = routes[url];
                    links.push(element);
                }
            }

        }

        this.setState({ links })
    }
    render() {
        return (''
        //     <div style={{ padding: 10, zIndex: 100, background: "white" }}>
        //         <Breadcrumb>
        //             {
        //                 this.state.links.map((item, index) => {
        //                     let active = false;
        //                     let LinkComponent = <Link to="/" key={index}>{item.label}</Link>;
        //                     let isLast = this.state.links.length ===index + 1;
        //                     if (isLast) {
        //                         active = true;
        //                         LinkComponent = <span key={index} style={{ color: "#707070" }}>{item.label}</span>;

        //                     }
        //                     return <>

        //                         <Breadcrumb.Section link active={active}>
        //                             {LinkComponent}
        //                         </Breadcrumb.Section>
        //                         {
                                    // !isLast &&
        //                             <Breadcrumb.Divider />
        //                         }
        //                     </>
        //                 })
        //             }
        //         </Breadcrumb>
        //     </div>
        )
    }
}
