

const initState = {
    user1: "",
    sending: false,
    msgs: []
}
const chatReducer = (state = initState, action) => {
    switch(action.type){
        case "LOG": 
            return {
                ...state,
                hasLoggedIn: "user"
            }
        default: 
            return state;
    }
}

export default chatReducer
