import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class Pageing extends Component {

    constructor(props) {
        super(props)
        this.count = props.count;


        this.pageNo = 1;

        this.rowOfPage = props.rowOfPage


        this.pages = this.count / this.rowOfPage;


        console.log(this.count ,':',this.rowOfPage);

        this.pagengTag = []

        this.query = props.query
        this.queryAr = []

        this.query = this.query.replace('?', '').split('&');

        this.query.forEach(element => {
            if (element.indexOf('pageNo') === -1)
                this.queryAr.push(element)
            console.log(element.indexOf('pageNo'));
        });
        this.query = this.queryAr.join('&');


        for (let index = 0; index < this.pages; index++) {


            this.pagengTag.push(index)

        }

    }








    render() {





        let width = '30px'


        return (
            <div>


                {
                    this.pages > 1 &&
                    this.pagengTag.map((data, i) => {

                        let index = i + 1;

                        return <div style={{
                            width: width, height: width,
                            paddingTop: '5px', textAlign: 'center',
                            backgroundColor: '#fff ', margin: '2px', display: 'inline-block',
                        }}>
                            <Link to={'?pageNo=' + i + '&' + this.query}
                                style={{
                                    width: width, height: width,
                                    display: 'block'
                                }}

                                className={"form-control "}




                            >{index}</Link>
                        </div>
                    }
                    )
                }
            </div>
        )
    }
}