
import { loginTypes } from '../actions/actionTypes';

const initState = {
    loading: false,
    checking: false,
    isLoggedIn: false,
    loginType: '',
    accessToken: "2324",
    username: "demo",
    user: {
        image: "",
        phone: "",
        email: "",

    }
}

const loggedOutState = { ...initState };

const loginReducer = (state = initState, action) => {
    let user, accessToken;
    switch (action.type) {
        case loginTypes.logUserIn:
            return {
                ...state,
                hasLoggedIn: true
            }
        case loginTypes.fbLoging:
            return {
                ...state,
                username: "ali"
            }
        case loginTypes.setTokens: {
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.value.user,
                },
                loginType: action.value.loginType,
                accessToken: action.value.accessToken,
                isLoggedIn: true,
                checking: false,
            }
        }

        case loginTypes.loginDone:
            user = action.value.user;
            accessToken = action.value.accessToken;

            return {
                ...state,
                checking: false,
                loading: false,
                accessToken,
                user: user,
                isLoggedIn: true,
                loginType: action.value.loginType,
            }
        case loginTypes.error:

            return {
                ...state,
                isLoggedIn: false,

            }
        case loginTypes.saveProfileData:

            return {
                ...state,
                user: user
            }
        case loginTypes.notLoggedIn:

            return {
                ...initState
            }
        case loginTypes.checkLoggedIn:
            return {
                ...state,
                checking: true,
            }
        case loginTypes.loading:

            return {
                ...state,
                loading: true
            }

        case loginTypes.logOut:

            return loggedOutState

        default:
            return state;
    }
}

export default loginReducer