import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export default class CustCount extends Component {

    constructor(props) {
        super(props)
        this.custCount = props.custCount;



    }


    render() {

        if (this.custCount == false) {
            return <></>
        }

        let all = this.custCount.all
        let month = this.custCount.month
        let week = this.custCount.week
        let day = this.custCount.day
        return (
            <div style={{
                width: '100%',
                alignItems: 'end'

            }}
            >
                <div className='cardRow'>

                    <div className='cardItem'>الكل </div>
                    <div className='cardItem2'>{all}</div>
                    <div className='cardItem'><nobr> سجلات الشهر</nobr></div>
                    <div className='cardItem2'>{month}</div>
                    <div className='cardItem'><nobr>سجلات الاسبوع </nobr></div>
                    <div className='cardItem2'> {week}</div>
                    <div className='cardItem'>اليوم</div>
                    <div className='cardItem2'> {day}</div>
                </div>



            </div>
        )
    }
}