import { appTypes } from "../actions/actionTypes";


const initState = {
    lang2: "ar",
    lang: "ar",
    right: 'left',
    left: 'right',
    langQuery: "lang=en",
    tableDetails: {
        tables: [],
        selectedData: {},
        footer: {}
    },
    values: {
        courses: []
    },
}
const appReducer = (state = initState, action) => {
    switch (action.type) {
        case appTypes.changeLang:
            return {
                ...state,
                lang: action.values.lang,
                right: action.values.lang ==='ar' ? 'right' : 'left',
                left: action.values.lang ==='ar' ? 'left' : 'right',
                langQuery: action.values.lang ==='ar' ? 'lang=ar' : 'lang=en',
            }
        case appTypes.setTableDetails:
            return {
                ...state,
                tableDetails: action.values.tableDetails
            }
        case appTypes.setStateValues:
            return {
                ...state,
                values: {
                    ...state.values,
                    ...action.values
                }
            }


        default:
            return state;
    }
}

export default appReducer
