import React from 'react'
import { Link } from 'react-router-dom';
import ActionMenu from '../ActionMenu/ActionMenu';
import { string } from '../../app/lang';
import { Button } from 'semantic-ui-react';
import ImgViewer from './ImgViewer';
import ModelDet from './ModelDet';
import ToCustomComponent from '../../app/local/ToCustomComponent';


import { FILESURL } from '../../app/local/config'
import numberPro from '../../pro/numberPro';


export const Tr = (props) => {
    let { data, actions, index, page, getUpdateData, tableAther, Th } = props;
    let item = [];
    let itemDet = [];
    let clickBtn = <></>;
    let link = [];

    let queryString = "";
    let selectedData = props.selectedData
    let footer = props.footer
    let formDetails = props.formDetails

    //توليد رابط عرض بيانات الصف


    // let queryString2 = props.parent.queryString2();



    if (props.onClick) {


        for (let j in props.onClick) {

            queryString = "";
            let names = props.onClick[j].names;

            if (props.onClick[j].fields)
                for (let i = 0; i < props.onClick[j].fields.length; i++) {


                    let field = props.onClick[j].fields[i];
                    let fieldData = data[field]

                    if (i === props.onClick[j].fields.length - 1) {
                        queryString += `${names[i]}=${fieldData}`;
                    } else {
                        queryString += `${names[i]}=${fieldData}&`;
                    }
                }
            if (props.onClick[j].const) {
                for (let item in props.onClick[j].const) {

                    let value = props.onClick[j].const[item]
                    queryString += `&${item}=${value}`
                }
            }








            let _cond = ',' + props.onClick[j].cond + ',';
            let _field = props.onClick[j].field;

            if (_cond === ',,' || _cond.indexOf(',' + data[_field] + ',') !== -1) {

                let arr = {};
                arr.to = props.onClick[j].page
                arr.label = props.onClick[j].label
                arr.queryString = queryString
                link.push(arr)
            }

            // <Link t/o={`${props.onClick[j].page}?${queryString}`} className="btn btn-success">{props.onClick[j].label}</Link>

        }



        clickBtn = <td style={{ textAlign: "center" }} className={'button'}><table><tbody><tr>
            {link.map((data, i) => <td key={i}>
                <Link


                    to={`${data.to}?${data.queryString}`} className="btn btn-success">{data.label}
                </Link>
            </td>
            )
            }
        </tr></tbody></table></td>



    }

    let modelDetTag = <></>
    let modelToCustomCom = <></>


    let cut = props.cut ? props.cut : []; // العناصر المراد إقتصاصها من العرض

    let c = -2;


    let itemId = data[0];

    for (let i in data) {

        c++;
        if (cut.indexOf(i) !== -1)
            continue;

        if (!Th[c])
            continue;


        if (Th[c].total) {


            footer[i] += data[i] * 1


        }

        if (typeof (data[i]) === "object" && data[i] !== null) { }
        // else if (i!='id')  {
        else if (Th[c].type === 'image') {
            let imageShow = <ImgViewer
                label={Th[c].label}
                url={data[i]}
            ></ImgViewer>
            if (data[i] == null) imageShow = 'لا توجد صورة';
            itemDet.push(imageShow)
            if (props.showInRep[i] === 1) item.push(imageShow)

        }
        else if (Th[c].type === 'files') {
            let fileShow = <a
                href={FILESURL + "/images/" + data[i]}
                target={'_blank'}
                rel="noopener noreferrer"

            >عرض الملف</a>
            if (data[i] == null) fileShow = 'لا يوجد ملف';
            itemDet.push(fileShow)
            if (props.showInRep[i] === 1) item.push(fileShow)

        }

        else if (Th[c].type === 'fromQuery') {
            let fromQuery = data[i];
            if (data['_' + i])
                fromQuery = data['_' + i].name;
            itemDet.push(fromQuery)
            if (props.showInRep[i] === 1) item.push(fromQuery)
        }
        else if (Th[c].eval) {

            let resultEq = '';
            let evelFields = Th[c].eval.f;
            let evelEq = Th[c].eval.eq;

            evelFields.forEach(element => {
                let value = data[element];
                if (value == null)
                    value = 0
                evelEq = evelEq.replace(element, `(${value})`);



            });


            resultEq = eval(evelEq);



            itemDet.push(resultEq)
            data[i] = resultEq
            if (props.showInRep[i] === 1) item.push(resultEq)

        }
        else if (selectedData[page] && selectedData[page][i]) {


            let evalField = selectedData[page][i].evalField;
            let evalText = selectedData[page][i].evalText;

            if (selectedData[page][i]['count']) {
                let field = selectedData[page][i].field;
                let key = selectedData[page][i].key;
                let indextable = tableAther.indexOf(selectedData[page][i].fromTable)
                let data2 = getUpdateData[indextable]
                let count = 0;
                for (let i in data2) {
                    if (data2[i][key] === data.id)
                        count += data2[i][field]
                }
                itemDet.push(count)
                if (props.showInRep[i] === 1) item.push(count)
                data[i] = count;

            } else {
                if (evalText) {
                    for (let j in evalField) {

                        evalText = evalText.replace(evalField[j], data[evalField[j]]);

                    }
                    itemDet.push(evalText)
                    if (props.showInRep[i] === 1) item.push(evalText)
                    data[i] = evalText
                } else {
                    if (selectedData[page][i].fromTable) {
                        if (data['_' + i]) {
                            itemDet.push(data['_' + i].name)
                            if (props.showInRep[i] === 1) item.push(data['_' + i].name);

                        }
                        else {
                            itemDet.push('-')
                            if (props.showInRep[i] === 1) item.push('-');
                        }

                        // if (tableAther) {
                        //     let indextable = tableAther.indexOf(selectedData[page][i].fromTable)

                        //     if (getUpdateDataKey[indextable]) {
                        //         let name = selectedData[page][i].name
                        //         let text = selectedData[page][i].text





                        //         let index = getUpdateDataKey[indextable].indexOf(data[i] * 1);



                        //         let f = '';


                        //         if (name) {
                        //             for (let j in name) {
                        //                 if (getUpdateData[indextable][index])
                        //                     f = getUpdateData[indextable][index][name[j]];
                        //                 text = text.replace(name[j], f)
                        //             }
                        //             f = text
                        //         }
                        //         else
                        //             if (getUpdateData[indextable][index]) {

                        //                 f = getUpdateData[indextable][index].name;

                        //             }
                        //         itemDet.push(f)
                        //         if (props.showInRep[i] === 1) item.push(f)
                        //     }
                        //     else {
                        //         itemDet.push('--')
                        //         if (props.showInRep[i] === 1) item.push('--');
                        //     }
                        // }
                        // else {
                        //     itemDet.push('---')
                        //     if (props.showInRep[i] === 1) item.push('---');
                        // }

                    } else {

                        if (selectedData[page][i].fromDataBase) {

                            itemDet.push(data[i])
                            if (props.showInRep[i] === 1) item.push(data[i])
                        }
                        else {
                            itemDet.push(selectedData[page][i][data[i]])
                            if (props.showInRep[i] === 1) item.push(selectedData[page][i][data[i]])
                        }
                    }
                }
            }
        }
        else if (Th[c].type === 'countable') {
            let value = numberPro.dotted(data[i]);
            itemDet.push(value)
            if (props.showInRep[i] === 1) item.push(value);
        }
        else
            if (data['_' + i]) {
                itemDet.push(data['_' + i].name)
                if (props.showInRep[i] === 1) item.push(data['_' + i].name);
            }
            else {
                itemDet.push(data[i])
                if (props.showInRep[i] === 1) item.push(data[i]);
            }



    }

    if (props.modelDet === 2) {
        modelDetTag = <td><ModelDet data={itemDet} Th={Th} id={itemId}></ModelDet></td>
    }
    if (props.toCustom) {
        console.log('com');
        modelToCustomCom = <td><ToCustomComponent data={{ itemDet: itemDet, Th: Th, id: itemId, page: page }}></ToCustomComponent></td>
    }

    c = -2;
    item = item.map((data, i) =>
        <td key={i} style={{ textAlign: "center" }} className="table-data"><nobr>{data}</nobr></td>
    );

    let editBtn, delBtn, btns = "";
    let links;

    if (actions) {

        let { parent } = props;
        let id = props.pk


        links = []
        if (actions.edit) {
            links.push({ title: string("edit"), onClick: () => parent.genUpdateFields(index, id, ''), icon: "edit", color: "green" });
        }
        if (actions.delete) {
            links.push({ title: string("delete"), onClick: () => parent.confirmDelete(index, id), icon: "remove", color: "red" });
        }
        btns = <nobr>{delBtn} {editBtn}</nobr>
    }

    let customeBtn = <></>;
    if (props.customeBtn) { // الأزرار المراد إظهاراها في الجدول


        customeBtn = <td>
            {props.selectDriver}
            <button className="btn btn-success" onClick={() => props.customeBtn.onClick(data.id, index)}>
                <nobr>{props.customeBtn.label} </nobr>
            </button>
        </td>
    }

    if (btns !== "") {

        /**
         * قائمة الخيارات
         * @params link= مصفوفة تحتوي على أزرار التعديل والحدف
         */
        let actionMenu = <ActionMenu links={links} icon={props.menuIcon} />;
        return <tr>
            {item}
            {clickBtn}
            {customeBtn}
            {modelDetTag}
            {modelToCustomCom}
            {
                props.renderCustomeAction &&
                props.renderCustomeAction(props.data)
            }
            {
                props.formDetails &&
                props.formDetails.map((data2, i) => {
                    let ret = 1
                    let fieldCond = props.formDetails[i].field;
                    let fieldValue = props.formDetails[i].value;


                    let color = 'green'
                    if (data2.color)
                        color = data2.color

                    console.log('data :', data);
                    if (fieldCond)
                        if (data[fieldCond] * 1 !== fieldValue * 1)
                            ret = 0
                    if (ret === 1 && (data.days != 0 || fieldCond == 'days'))
                        return <td>

                            <Button color={color} className="btn btn-success"
                                onClick={() => props.parent.genUpdateFields(index, data.id, formDetails[i])}
                            >

                                {props.formDetails[i].renderEditBtn}
                            </Button>
                        </td>
                    else
                        return <td></td>
                    // console.log(1)
                }
                )

            }
            <td className="table-btns ">{actionMenu}</td>
        </tr>
    }
    return (
        <tr>
            {item}
            {customeBtn}

        </tr>
    )
}