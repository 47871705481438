import React from 'react'

import { Table as Table2, Button } from 'react-bootstrap';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import '../path/pass.css'


// import Confirm from './confirm/Confirm';

// import Modal from './Modal/Modal';
import { Confirm, Icon } from 'semantic-ui-react';
import Base from '../../pro/Base';
import { Rows } from './Row';
import { Th } from './Th';
import { FooterData } from './FooterData';
import Form from '../input/Form';
import { string } from '../../app/lang';
import Card from '../card/Cards';
import PathLink from '../path/PathLink';
import Pageing from './Pageing';
import CustCount from './CustCount';



class Table extends Base {
    constructor(props) {
        super(props);
        this.pk = props.pk;
        this.page = this.props.page;
        this.list = this.props.list;
        this._total = this.props._total;
        // this._total = 1;
        this.tableAther = this.props.tableAther;
        this.getUpdateDataKey = [];
        this.getUpdateData = [];
        this.inputs = []
        this.fieldValues = {}

        this.getUpdateDataCount = localStorage.getItem("getUpdateDataCount" + this.page);


        if (this.tableAther) {
            for (let i in this.tableAther) {
                let data = localStorage.getItem("getUpdateData" + this.tableAther[i])
                let key = localStorage.getItem("getUpdateDataArray" + this.tableAther[i])



                this.getUpdateData.push(JSON.parse(data))
                this.getUpdateDataKey.push(JSON.parse(key))

            }


        }


        this.updateFields = [];

        this.onCancelDelete = this.onCancelDelete.bind(this);
        this.toggleModel = this.toggleModel.bind(this);
        this.state = {
            ...this.state,
            list: this.props.list,
            showConfirm: false,
            showUpdateForm: false,
            updateForm: false,
            updateFields: [],
            updateSubmit: { value: string("accept"), type: "edit", page: this.props.page },

            // متغير يتم إستخدامه في عملية تعديل البيانات داخل النموذج 
            // يتم إرسل هذا الرقم مع الحقول إلى دالة تعديل البيانات على السيرفر
            primaryKey: "",
            deleteId: {},
            footerFields: [],
            hasFooter: false,
            deleteIndex: 0,
            currentIndex: 0,
            updateFormProps: {},
        }




    }

    componentDidMount() {
        super.componentDidMount();
        this.createFooterFields();
    }
    async componentWillUnmount() {
        super.componentWillUnmount();
    }
    updateFinished = (field, index) => {
        let newTable = this.props.parent.state.table;
        let currentField = newTable[index];
        currentField = { ...currentField, ...field }

        newTable[index] = currentField;
        this.props.parent.changeState("table", newTable);

    }

    /**
     * توليد حقول مجموع الحقول
     */
    createFooterFields = () => {
        const footerTable = this.props.tableDetails.footer

        let firstRow = this.props.list[0];
        let page = this.page;
        if (footerTable[page]) {
            this.setState({ hasFooter: true })
            if (firstRow) {
                let list = this.props.list;
                let footerFields = {};
                let footerArray = footerTable[page]
                for (let index = 0; index < list.length; index++) {
                    firstRow = list[index];
                    for (let item in firstRow) {
                        if (this.props.cut.includes(item)) {
                            continue;
                        }
                        if (footerArray.includes(item)) {
                            let intVal = firstRow[item] * 1;
                            if (intVal) {
                                if (!footerFields[item]) {
                                    footerFields[item] = firstRow[item] * 1
                                } else {
                                    footerFields[item] += firstRow[item] * 1
                                }
                            }

                        } else {
                            footerFields[item] = "";
                        }
                    }
                }
                let newFooterFields = [];
                if (footerFields) {
                    for (let item in footerFields) {
                        newFooterFields.push(footerFields[item])
                    }
                }
                this.setState({
                    footerFields: newFooterFields
                })
            }
        }
    }


    // توليد حقول التعديل من جدول عرض البيانات 
    genUpdateFields = async (index, id, formDetails) => {

        let tables = this.props.tableDetails.tables
        let selectedData = tables.selectedData;
        let fields = tables[this.page].fields;
        let values = this.props.list[index];
        this.updateFields = [];
        this.editPK = id;


        // get the primary key form data 
        this.editPK = { [this.props.pk]: values[this.props.pk] };


        this.changeState('updateform', '')
        for (let i in fields) {
            let element = fields[i];
            let { itemID } = element;
            let cut = this.props.cut ? this.props.cut : []; // العناصر المراد إقتصاصها من العرض

            if (formDetails) {
                if (formDetails.editFields.includes(element.itemID)) {
                    if (values[itemID]) {
                    }

                    let field = element;
                    field.value = `${values[itemID]}`;
                    field.status = "";
                    this.updateFields.push(field);
                    continue;
                }

            } else {
                // التحقق مما إذ الحقل الحالي هو من ضمن الحقول التي يتوجب عدم عرضها أو لا 
                if (cut.indexOf(element.itemID) !== -1) {
                    continue;
                }
                // توليد بارميترات جدول التعديل لتمريرها إلى النموذج
                // <Form {... updatefields} />
                if (values[itemID]) {
                }

                let field = element;
                field.value = `${values[itemID]}`;
                field.status = "";
                this.updateFields.push(field);
            }


        }

        //             إضافة القيمة الثابتة
        if (formDetails) {
            if (formDetails.defaultData) {
                for (let i in formDetails.defaultData) {
                    let element = formDetails.defaultData[i]
                    let field = {};
                    field.itemID = i;
                    field.label = formDetails.editTitle;
                    field.value = element;
                    field.status = "";
                    field.type = 'hidden'
                    this.updateFields.push(field);
                }
            }

        }

        // تخزين بينانات الحقول متغير الـ state
        this.setState({
            updateFields: this.updateFields,
            primaryKey: this.editPK,
            currentIndex: index,
            updateForm: true,
        })

        // تعديل حالة ظهور جدول التعديل من إلى نعم 
        this.toggleModel();
        this.changeState('updateFormProps', {
            open: this.state.showUpdateForm,
            onClose: this.hideUpdateForm,
            parent: this.props.parent,
            title: '111111111111',
            selectedData: selectedData,
            fields: this.state.updateFields,
            submit: this.state.updateSubmit,
            updateKey: this.state.primaryKey,
            onFinish: (field) => this.updateFinished(field, index),
        })
        // this.changeState('updateForm', <Form
        //     open={this.state.showUpdateForm}
        //     onClose={this.toggleModel}
        //     parent={this.props.parent}
        //     selectedData={selectedData}
        //     fields={this.state.updateFields}
        //     submit={this.state.updateSubmit}
        //     updateKey={this.state.primaryKey}
        //     onFinish={(field) => this.updateFinished(field, index)}
        // />)



    }
    hideUpdateForm = () => {

        this.setState({
            showUpdateForm: false
        })
    }
    toggleModel() {
        this.changeState("showUpdateForm", !this.state.showUpdateForm)
    }
    onCancelDelete() {
        this.changeState('showConfirm', false);
    }
    confirmDelete = (index, id) => {
        let value = this.props.list[index];
        value = { [id]: value[this.props.pk] };

        this.changeState('showConfirm', true);
        this.changeState('deleteId', value);
        this.changeState('deleteIndex', index);
    }

    doDelete = async () => {
        let deleteId = this.state.deleteId;
        let index = this.state.deleteIndex;


        await this.deleteItem(this.page, deleteId);
        if (this.state.deleted.error === false) {
            let newTableData = this.props.parent.state.table;
            newTableData.splice(index, 1)
            this.props.parent.changeState("table", newTableData);
            this.changeState('showConfirm', false);
        } else {
            alert(string("connectionError"))
            this.changeState('showConfirm', false);
        }

        return true;
    }

    setUrl = async (condText, page) => {


        this.props.history.push('/' + page + '?' + condText)

    }

    set_url = async () => {
        this.seturl();
    }
    seturl = async () => {
        let cond = [];
        for (let i in this.inputs) {


            // console.log(i);

            if (i.indexOf('.To') === -1) {
                if (i.indexOf('.From') >= 0) {
                    let j = i.split('.');
                    let To = this.inputs[j[0] + '.To'].value;
                    if ((this.inputs[i].value + ':' + To) !== ':')
                        cond.push(j[0] + '=' + this.inputs[i].value + ':' + To);
                }
                else
                    if (this.inputs[i].value)
                        cond.push(i + "=" + this.inputs[i].value)
            }

        }

        let condText = cond.join('&')

        console.log(condText);

        if (cond.length > 0) {

            let filterPage = "";
            this.props.parent.setUrl(condText, filterPage)

        }

    }
    render() {
        let addNew = '';
        let foreginKey = "";

        let renderEditBtn
        let formDetailsTd = 1;

        if (this.props.formDetails) {
            renderEditBtn = this.props.formDetails.renderEditBtn;
            formDetailsTd = 2

        }
        let { selectedData, footer } = this.props.tableDetails


        if (this.props.addNew && this.props.setAddBt) {
            if (this.props.addNew.foreginKey) {
                foreginKey = "?"
                const foreginKeyObj = this.props.addNew.foreginKey;
                let objLength = Object.keys(foreginKeyObj).length
                let i = 0;
                for (let key in foreginKeyObj) {
                    if (i < objLength - 1) {
                        foreginKey += `${key}=${foreginKeyObj[key]}&`
                    } else {
                        foreginKey += `${key}=${foreginKeyObj[key]}`
                    }
                    i++;
                }

            }
            addNew = <div style={{ margin: 35, justifyContent: "space-evenly", }}>
                <Link to={`addNew-${this.props.addNew.page}${foreginKey}`} className="btn btn-success" style={{ marginLeft: "auto" }}>
                    <Icon name="add circle" />
                    <span>{string("addNew")}</span>
                </Link>
                {this.props.children}
            </div>
        }
        if (this.props.children && !this.props.addNew) {
            addNew = <div style={{ margin: 5, display: 'flex', justifyContent: "space-evenly", textAlign: "center" }}>
                {this.props.children}
            </div>
        }
        if (this.state.updateForm === true) {
            // updateForm = <div>
            //     <div>
            //         <Form fields={this.state.updateFields} submit={this.state.updateSubmit} updateKey={this.state.primaryKey} />
            //     </div>

            // </div>
        }
        let hasAction = false;
        if (this.props.actions) {
            hasAction = true;
        }

        let showAction = false;
        if (this.props.showAction) {
            showAction = this.props.showAction;
        }
        if (!this.props.list[0]) {
            return <div>
                {addNew}
            </div>
        }

        let hh = this.props.tables[this.page].fields;
        let _total = [];


        let tfoot = <tfoot><tr><td >-</td></tr></tfoot>;

        let index = []
        if (this._total) {
            for (let i in hh) {
                _total.push(footer[hh[i].itemID]);
                footer[hh[i].itemID] = 0;

                index.push(hh[i].itemID);

                if (_total[i] === 1) {
                    _total[i] = '-';
                }


            }
            console.log('footer', footer);

            console.log('tootal:', _total);


            let td = _total.map((data, i) =>

                <td key={i} style={{ textAlign: "center" }} className="table-data">{footer.value}</td>)


            tfoot = <tfoot><tr><td>{string("total")}</td>{td}</tr></tfoot>
        }
        let filters = '';
        let filtersBT = '';
        let statusFilter = 0;
        let list = this.props.tables[this.page].fields;

        filters = list.map((data, i) => {

            console.log(data);

            if (data.setFilter === 2) {

                statusFilter = 1;

                if (data.type === 'select') {

                    let selectItem = ''
                    let selectItemOb = []

                    if (data.fromTable) {
                        selectItem = localStorage.getItem("getUpdateData" + data.fromTable);


                        selectItem = JSON.parse(selectItem);




                        let selectId = 'id'

                        let selectSh = data.name
                        let selectText = data.text
                        for (let i in selectItem) {

                            let name = ''
                            if (selectSh) {
                                let selectTex = selectText
                                for (let j in selectSh) {

                                    name = selectItem[i][selectSh[j]]

                                    selectTex = selectTex.replace(selectSh[j], name)

                                }
                                name = selectTex
                            }
                            else
                                name = selectItem[i]['name']

                            let itm = { 'id': selectItem[i][selectId], 'name': name }

                            selectItemOb.push(itm);
                        }

                    }
                    else {
                        selectItem = selectedData[data.selectIndex][data.itemID]


                        for (let i in selectItem) {
                            let itm = { 'id': i, 'name': selectItem[i] }
                            selectItemOb.push(itm);
                        }
                    }



                    let optionDefult = <option key="0" value="all">الكل</option>
                    let option = ""
                    let hide = '';

                    return (

                        <label key={i + 1000} className={"col-sm-3 " + hide}>
                            <label key={i + 100}>{data.label}</label>
                            <select key={i} itemID={data.itemID} className={"form-control input-f"} ref={ref => {
                                this.inputs[data.itemID] = ref;
                                this.fieldValues[data.itemID] = '';

                            }}

                                defaultValue={data.default} >
                                {optionDefult}
                                {selectItemOb.map((selectItemOb, i) => <option value={selectItemOb['id']} key={i}>{selectItemOb['name']}</option>)}

                            </select>
                        </label>

                    )
                }
                return (
                    <label key={i + 1000} className={"col-sm-3 "}>
                        <label key={i + 100}>{data.label}</label>
                        <input key={i} itemID={data.itemID} type={data.type} className={"form-control input-f"} ref={ref => {
                            this.inputs[data.itemID] = ref;
                            this.fieldValues[data.itemID] = '';
                        }}
                            defaultValue={data.default} />
                    </label>
                )
            }
            else
                if (data.setFilter === 3) {

                    statusFilter = 1;


                    return (
                        <label key={i + 1000} className="filter-item">
                            <label key={i + 100}>{data.label}</label><br></br>
                            <input key={i} itemID={data.itemID + ".From"} type={data.type} className={"form-control filterDate"} ref={ref => {
                                this.inputs[data.itemID + ".From"] = ref;
                                this.fieldValues[data.itemID + ".From"] = '';
                            }}

                                defaultValue={data.default} />
                            <input key={i} itemID={data.itemID + ".To"} type={data.type} className={"form-control filterDate"} ref={ref => {
                                this.inputs[data.itemID + ".To"] = ref;
                                this.fieldValues[data.itemID + ".To"] = '';
                            }}

                                defaultValue={data.default} />

                        </label>
                    )
                }
                else
                    return ('')
        });

        if (statusFilter === 1) {
            filtersBT = <div className={'filterBtCont'}>

                <Button onClick={this.seturl} className="btn btn-success" >بحث</Button>
            </div>

            filters = <div className={'filterCont'}>
                {filters}
                <div>{filtersBT}</div>
            </div>
        }


        let showInRep = {};
        let TH = this.props.tables[this.page].fields;
        if (TH) {
            TH.map((data) => {

                showInRep[data.itemID] = data.showInRep;

                return ''
            }
            );
        }
        return (
            <React.Fragment>
                <section>
                    {/* <ContextMenu /> */}
                    {
                        this.props.pagePath &&
                        <PathLink pagePath={this.props.pagePath} />

                    }

                    <div className="table-wrapper" style={{ position: "relative" }}>


                        <div>{filters}</div>

                        {addNew}
                        
                        <CustCount custCount={this.props.custCount}></CustCount>
                        
                        {

                            this.props.cards ?
                                <Card
                                    parent={this.props.parent}
                                    page={this.page}
                                    fields={this.props.cards}
                                    data={this.props.list}

                                    hasShowAction={showAction}
                                    cut={this.props.cut}
                                    actions={this.props.actions}
                                    {...this.props}
                                />
                                :

                                <Table2 striped bordered condensed hover responsive style={{ background: "white" }}>
                                    <thead>
                                        <Th
                                            page={this.page}
                                            cut={this.props.cut}
                                            data={this.props.tables[this.page].fields}
                                            action={hasAction} hasShowAction={showAction}
                                            renderEditBtn={renderEditBtn}
                                            formDetailsTd={formDetailsTd}
                                            modelDet={this.props.modelDet}
                                            toCustom={this.props.toCustom}

                                        />
                                    </thead>
                                    <tbody>
                                        <Rows
                                            {...this.props}

                                            Th={this.props.tables[this.page].fields}
                                            push={this.props.parent.props.history ? this.props.parent.props.history.push : () => null}
                                            tr={this.props.list}
                                            selectedData={selectedData}
                                            footer={footer}
                                            customeBtn={this.props.customeBtn}
                                            hasShowAction={showAction}
                                            cut={this.props.cut}
                                            actions={this.props.actions}
                                            parent={this}
                                            pk={this.props.pk}
                                            page={this.page}
                                            getUpdateData={this.getUpdateData}
                                            getUpdateDataKey={this.getUpdateDataKey}
                                            getUpdateDataCount={this.getUpdateDataCount}
                                            menuIcon={this.props.menuIcon}
                                            tableAther={this.tableAther}
                                            showInRep={showInRep}

                                            renderCustomeAction={this.props.renderCustomeAction}
                                        />
                                    </tbody>

                                    {

                                        <FooterData
                                            page={this.page}
                                            cut={this.props.cut}
                                            data={this.props.tables[this.page].fields}
                                            action={hasAction} hasShowAction={showAction}
                                            renderEditBtn={renderEditBtn}
                                            formDetailsTd={formDetailsTd}
                                            modelDet={this.props.modelDet}
                                            fields={this.state.footerFields}
                                            pageTotals={this.props.pageTotals}
                                            rowsTotals={this.props.rowsTotals}
                                            count={this.props.count}
                                            rowOfPage={this.props.rowOfPage}
                                        />
                                    }
                                </Table2>
                        }
                        <Pageing
                            count={this.props.count}
                            rowOfPage={this.props.rowOfPage}

                            query={this.props.query}></Pageing>
                    </div>
                </section>
                <Confirm
                    open={this.state.showConfirm}
                    header={string("itemWillDelete")}
                    content={string("doYouWantToContinu")}
                    onCancel={this.onCancelDelete}
                    onConfirm={this.doDelete}
                />
                {/* <Confirm
                    show={this.state.showConfirm}
                    onAgree={this.doDelete}
                    onCancel={this.onCancelDelete}
                    title="هل تريد الإستمرار؟"
                >
                    
                </Confirm> */}
                {/* {this.state.updateForm} */}
                {
                    this.state.showUpdateForm &&
                    <Form
                        formDetails={this.props.formDetails}
                        parent={this.props.parent}
                        selectedData={selectedData}
                        submit={this.state.updateSubmit}
                        {...this.state.updateFormProps}
                        onFinish={(field) => this.updateFinished(field, this.state.currentIndex)}
                        updateKey={this.state.primaryKey}
                        fields={this.state.updateFields}
                        open={this.state.showUpdateForm}
                        onClose={this.toggleModel}
                    />
                }

                {/* <Modal 
                    show={this.state.showUpdateForm}
                    onAgree={this.toggleModel}
                    header={"تعديل البيانات"}
                    onCancel={this.toggleModel}
                >
                    {this.state.updateForm}

                </Modal> */}
            </React.Fragment>
        )
    }
}






const getState = (state) => {
    return {
        accessToken: state.auth.accessToken,
        isLoggedIn: state.auth.isLoggedIn,
        checking: state.auth.checking,
        tableDetails: state.app.tableDetails,
        tables: state.app.tableDetails.tables,
    }
}

export default connect(getState, {})(Table);
