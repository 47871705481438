

// const URL = `http://localhost:3094/api`;
// export const FILESURL = `http://localhost/cp`;

const URL = `https://rafeeq.erpfast.online/api`;
export const  FILESURL = `https://rafeeq.erpfast.online`;



export const TOKEN_NAME = "fksdfsdSDF334DFSkl.xceitF3349MnW@weori293[eriwe,cmwiwoemvnwwwiv#4RE2W";

export const ACCESS_TOKEN = md5("fksdfsdSDF334DFSkl.xceitF3349MnW@weori293[eriwe,cmwiwoemvnwwwiv#4RE2W");
export const GUEST_NAME = "sss";
export const TOKEN_USER = md5("USER_FROM_STORAGE");
export const TOKEN_TYPE = md5("USER_TOKEN_TYPE");

export const logMsg =1;

export const SITE_NAME = "الرفيق";
export const SUBFOLDER = "";
export default URL;


function md5(data) {
    let crypto = require("crypto");
    return crypto.createHash("md5").update(data).digest("hex");
}


