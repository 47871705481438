import { reducerActions } from '../reducerActions';
import { dbTypes } from '../actions/actionTypes';

const initState = {
    item: 3,
    menuLinks: []

}
const dbReducer = (state = initState, action) => {
    switch (action.type) {
        case reducerActions.deleteItem:
            return {
                ...state,
                item: state.item + 3
            }
        case dbTypes.setMenuLinks:
            return {
                ...state,
                menuLinks: action.values.menuLinks[0] ? action.values.menuLinks : state.menuLinks,
            }
        default:
            return state;
    }
}

export default dbReducer