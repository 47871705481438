import React from 'react'
import { connect } from "react-redux";
import Base from './Base';
import Page from './Page';

import { TableNotFound } from './TablesError';
// import queryString from 'query-string';
import { Button } from 'semantic-ui-react';
import Form from '../component/input/Form';
import { string } from '../app/lang';

class AddNew extends Base {
    constructor(props) {

        let state = { name: 'ali', age: 33 }
        super(props, state)
        this.page = this.props.match.params.page;
        this.forgeinkey = this.queryString()

    }
    render() {
        let pageData;
 
        if (!this.props.tables[this.page]) {
            pageData = <TableNotFound />
        } else {
            if (this.props.tables[this.page].fields) {
                this.fields = this.props.tables[this.page].fields;
                // this.page = this.props.tables[this.page].page;

                let submit = { value: "send", type: "insert", page: this.page }
                pageData = <Form fields={this.fields} pageType={'addNew'}
                    submit={submit} forgeinkey={this.forgeinkey} />
            } else {
                pageData = string("connectionError")
            }
        }
        return (
            <div>
                <Page>
                    <section>
                        <div className="background"></div>
                        <div className="table-wrapper" style={{ position: "relative", padding: 10 }}>
                            <div style={{ padding: 5, textAlign: "left" }}>
                                <Button color="blue" onClick={this.goBack}>{string("goBack")}</Button>
                            </div>
                            {pageData}
                        </div>
                    </section>
                </Page>
            </div>
        );
    }

}
const getState = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        checking: state.auth.checking,
        tableDetails: state.app.tableDetails,
        tables: state.app.tableDetails.tables,
    }
}

export default connect(getState, {})(AddNew);
