import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react';
import { FILESURL, } from '../../app/local/config';



// import "./input.css"


export default class ImgViewer extends Component {

    constructor(props) {
        super(props)
        this.status = props.status;
        this.labelCaption = props.labelCaption;
        this.type = props.type;
        this.itemID = props.itemID;
        this.label = props.label;
        this.url = props.url;






        this.state = {

            showLife: false,


        }
    }
    selecLife = (event) => {
        event.preventDefault();
        this.props.onSelect(this.state.center);
        this.toggleMap(event);

    }
    toggleLife = (event) => {
        event.preventDefault();
        this.setState({ showLife: !this.state.showLife })
    }

    refValue = (event, value, name) => {
        event.preventDefault();
        this.props.onSelect(value);
        // this.toggleMap(event);
        this.toggleLife(event);
        this.setState({ 'nameTxt': name })
    }


    render() {



        return (
            <div>

                <div className="form-group col-12">
                    {this.labelCaption}

                    <button
                        type={this.type}
                        className={"form-control "}
                        itemID={this.itemID}
                        placeholder={this.placeholder}

                        onClick={this.toggleLife}
                    // ref={ref => {
                    //     props.parent.inputs[itemID] = ref;
                    //     props.parent.fieldValues[itemID] = '';
                    // }}
                    // defaultValue={value}


                    >عرض الصور</button>
                    <div className="text-danger">{this.status}</div>
                </div>

                <Modal
                    // dimmer={"blurring"}
                    open={this.state.showLife}
                    onClose={this.toggleMap}
                    size="large"
                    >
                    <Modal.Header> {this.label}
                        <div style={{ float: 'left' }}>
                            <Modal.Actions style={{ textAlign: "center" }}>

                                <Button color='black' onClick={this.toggleLife}>
                                    <span>تراجع</span>
                                </Button>
                            </Modal.Actions>
                        </div>

                    </Modal.Header>
                    <Modal.Content scrolling>
                        <img
                            style={{ width: "90%", margin: "auto" }}
                            alt={''}
                            src={FILESURL+'/images/'+this.url}></img>

                    </Modal.Content>

                </Modal>
            </div>
        )
    }
}