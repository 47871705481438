import React from 'react'
import { connect } from 'react-redux'
import Base from '../../pro/Base';


import Page from '../../pro/Page';

class Profile extends Base {
    constructor(props) {
        super(props);
        this.title = "الملف السخصي"
        this.isOwner = false;
        this.state = {
            ...this.state,
            errorStatus: "",
            loading: true,
            email: "",
            passwordChanged: false,
            messageHasSent: false,
            isLoading: false,
            isValidToken: false,
            user: { webSites: [], skills: [], image: { url: this.props.user.image } }
        }

        this.user = this.props.user;
        this.query = this.queryString();
        this.token = this.props.match.params.token
    }
    componentDidMount() {
        super.componentDidMount()
        this.checkToken();
    }
    checkToken = async () => {
        this.changeState("isValidToken", false)
        let url = "users/verifyUser"
        let fields = {
            verifyCode: this.token
        }

        let api = await this.api("post", url, fields);
        
        if (api.userExist ===true) {
            this.changeState("isValidToken", true)
        }
        this.changeState("loading", false)
    }
    _changePassword = async (event) => {
        event.preventDefault();
        this.changeState("status", "")
        this.changeState("isLoading", true)
        let password = this._password.value;
        let password2 = this._password2.value;



        // this.connecting();
        // let query = this.queryString();

        if (password.length >= 8) {
            if (password !== password2) {
                this.changeState("status", "كلمة المرور غير متوافقة")
                this.changeState("isLoading", false)

                return;
            }

            let url = "users/changePassword"
            let fields = {
                passwordToken: this.token,
                password
            }

            let api = await this.api("post", url, fields);

            if (api.passwordChanged ===false) {
                this.changeState("status", "عفواً، الرابط غير صالح")

            } else {
                // this.changeState("status", "تم إرسال رابط إلى عنوان البريد اﻹلكترون، قم بإستخدامه لتغيير كلمة المرور")
                this.changeState("passwordChanged", true)
            }
        } else {
            this.changeState("status", "كلمة المرور يجب ان تتكون من 8 أحرف على الأقل")
        }

        this.changeState("isLoading", false)
    }

    render() {
        return (
            <Page noFooter>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                        this.state.loading ?
                            <div style={{ padding: 20, textAlign: 'center' }}>
                                <this.Loading />
                                <h3>جاري التحقق</h3>
                            </div> :
                            this.state.isValidToken ?
                                <div className="col-md-6" style={{ padding: "20px", textAlign: 'center' }}>
                                    <h3 className="alert alert-success">
                                        تم تأكيد الحساب
                                <div>
                                         
      بإمكانك <a href={`${process.env.PUBLIC_URL}/login`}>الذهاب إلى تسجيل الدخول</a>
                                        </div>
                                    </h3>
                                </div> :
                                !this.state.isValidToken &&
                                <div className="col-md-6" style={{ padding: "20px", textAlign: 'center' }}>
                                    <h3 className="alert alert-danger">
                                        عفواً، هذا الرابط غير صالح
                                    </h3>
                                </div>

                    }
                </div>
            </Page >
        )
    }
}



const stateToProps = (state) => {

    return {
        isLoggedIn: state.auth.isLoggedIn,
        accessToken: state.auth.accessToken,
        user: state.auth.user,
    }
}

export default connect(stateToProps, {})(Profile);
