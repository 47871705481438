import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { createStore,applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk'

import './css/custome-bs.css';
import 'semantic-ui-css/semantic.min.css';
import './css/custome.css';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import reducers from './app/states/reducers';

const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));

ReactDOM.render(<Provider store={store}>
    <App />
    </Provider>, document.getElementById('root'));
registerServiceWorker();
