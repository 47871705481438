export default {
    translation: {
        appName: "-",

        //Home Page 
        homePage: "الرئيسية",
        whoWeAre: "من نحنُ",
        services: "الخدمات",
        projects: "المشاريع",
        offers: "سوق اﻹعلانات",
        welcome: "مرحبا بك في خدمتي",
        addProject: "إضافة مشروع",
        pageSubTitle: "تمتع بأفضل تجربة للعمل الحر لرواد اﻷعمال وقم بعرض خدماتك لتصل إلى أكبر عدد من المشتركين",
        startNow: "أبدأ مشروعك الآن",
        ourMessage: "مرحبا بك في موقع خدمتي إنضم الينا الان للاستمتاع بافضل تجربه للعمل الحر لرواد الاعمال علي المنصات العربيه",
        enterProjectTitle: "أدخل عنوان العمل",
        startNow2: "إبدأ الآن",
        homeText1: ` تستطيع علي خدمتي ان تنفذ مشروعك بمنتهي السهوله ، فقط قم بوصفه جيدا فخدمتي يوفر لعملائه مساحه واسعه للتحديد ما يريدونه بدقه ومن ثم قم بنشره ليصل الي امهر الفنيين في المجال المطلوب.`,
        homeText2: ` ستتلقي الكثير من العروض في هذه المرحله ، نحن سنحرص علي مساعدتك علي اختيار من هم انسب لتنفيذ مشروعك`,
        homeText3: `خدمتي يوفر لك افضل الطرق للدفع واكثرها التزاما وامانا ، احرص علي تقييم العميل بحياديه للمساهمه في تطوير العمل الحر .`,
        // about *************************
        whyWeAreGood: "لماذا نحن متميزون",
        about_projects: "معرض للمنتجات",
        about_getYorDreams: "حقق طموحك",
        about_rate: "إرتق في التقييم",
        about_trading: "سهولة في التداول",
        contactUs: "إتصل بنا",
        about_projectsDetails: "قم بعرض منتجاتك في خطوه واحده.",
        about_getYorDreamsDetails: "قم بإضافه خدماتك و مهاراتك للتعريف عن نفسك بطريقه احترافيه",
        about_rateDetails: "احرص علي الحصول علي تقييم ايجابي في كل مره.",
        about_tradingDetails: "ختر نوع العمله وقم بتحويل اموالك في منتهي السهوله والامان.",
        contactUsDetails: "تواصل معنا بسهوله لتلقي مشكلاتك وأقتراحاتك",

        // Categories
        relatedTo: "التصنيفات الخاصة بـ ",


        // Projects
        noData: "لم يتم العثور على نتائج",


        // Project Details





        noEmptyField: "لا يمكنك ترك أحد الحقول فارغاً",

        getingOffers: "مرحلة تلقي العروض",
        executing: "مرحلة التنفيذ",
        delivering: "مرحلة التسليم",
        projectFinished: "تهانينا على إكتمال العمل",
        finishedOk: "تم بحمد الله",
        isFinished: "تم اﻹنجاز",
        projectCard: "بطاقة المشروع",
        price: "الميزانية",
        finishTime: "مدة التنفيذ",
        offersAvg: "متوسط العروض ",
        offersCount: "عدد العروض",
        pOwner: "صاحب المشروع",
        pDetails: "تفاصيل المشروع",
        attachment: "الملفات المرفقة",
        comment: "التعليق",
        time: "الزمن",
        day: "يوم",
        userMoney: "مستحقاتك",
        addComment: "اضف تعليقك هنا",
        canNotAddOffers: " لا تستطيع اضافة عروض",
        pOffersDisabled: "تم تعطيل إستلام العروض",

        // CategoryList 
        category: 'التصنيف',
        categoryHighPrice: "سقف الميزانية",

        // Otheres 
        showDetails: "عرض التفاصيل",
        showMore: "عرض المزيد",
        mostUsed: "الأكثر شيوعاً",
        next: "السابق",
        prev: "التالي",
        more: "المزيد",

        // Services
        mustRate: "يتوجب عليك إضافة تقييم",
        rateService: "تقييم الخدمة",
        addServiceRate: "أضف تقييمك للخدمة",
        rate: "التقييم",

        // addToCart
        addToCart: "اضف إلى السلة",
        wilAddToCart: "سوف يتم إضافة الخدمة إلى السلة",
        removeFromCart: "حذف من السلة",
        willRemoveFromCart: "سوف يتم حذف الخدمة من السلة",
        cartCount: "حدد عدد مرات التنفيذ",
        doYouWantToContinue: "هل تريد اﻹستمرار؟",
        agree: "موافق",
        redo: "تراجع",

        // Header Title 
        sort: "الترتيب",
        modified: "الاحدث",
        maxPrice: "الأعلى سعراً",
        minPrice: "الأقل سعراً",

        // Errors 
        error: "عفواً حدث خطأ",
        error_containPhoneOrEmail: "لا يمكن للنص ان يحتوي على رقم هاتف أو بريد إلكتروني",
        error_tryAgain: "حدث خطأ، الرجاء المحاولة مرة أخرى",
        error_canNot: "عفواً لا يمكن إجراء الطلب",

        permissionError: 'خطأ في اﻹتصال بالسيرفر، تأكد من إتصالك بالإنترنت',
        connectionError: "خطأ في اﻹتصال بالسيرفر، تأكد من إتصالك بالإنترنت",
        notFoundReq: 'لم يتم العثور على الطلب',

        // Connection 
        connecting: "جاري الإتصال",

        // Login 
        loginWelcome: "مرحبا بعودتك! الرجاء تسجيل الدخول للمتابعه",
        email: "اسم المستخدم",
        password: "كلمة المرور",
        forgetPassword: "نسيت كلمه المرور",
        change: "تغيير",
        checked: "تم التحقق من البيانات",
        inValidLogin: "البريد الإلكتروني او كلمة المرور خاطئة",
        login: "دخول",

        // Table
        accept: "إعتماد",
        add: "إضافة",
        addNew: "إضافة سجل جديد",
        total: "المحموع",
        itemWillDelete: 'سيتم حذف هذا العنصر من النظام',
        doYouWantToContinu: "هل تريد الإستمرار؟",
        editData: "تعديل البيانات",

        // Tr
        edit: "تعديل",
        delete: "حذف",


        goBack: "رجوع",
        "404": "لم يتم العثور على الصفحة",
        dataSaved: 'تم حفظ البيانات',
    }
}