import React from "react";
import './card.css'
import History from "../../app/tools/History";
// import ContextMenu  from "../RCB/rcb";

const CardItem = (props) => {
   

    document.addEventListener("contextmenu", (event) => {
        event.preventDefault();
        // const xPos = event.pageX + "px";
        // const yPos = event.pageY + "px";
        //
      });
      
    // let test = (e) => {
    // //  alert(1);
    // console.log(e.clientX);
    // console.log(e.clientY);
    
    //  e.preventDefault();

    // }
    let onClick = {}
    if (props.clickBtn) {
        onClick = {
            onClick: (event) => {
                event.preventDefault();
                History.push(props.clickBtn);
            },
            style: {cursor: "pointer"}
        }
    }
    let a= onClick;
    onClick=a
    let data = 
    // <div className="folder-card" onContextMenu={test}>
    //     {/* <Rcb /> */}
    //         <div className="image-card">
    //           <span> <i className="fad fa-folders fa-4x"></i> </span>
    //         </div>
    //         <h5 className="folder-title">
    //           <span>العنوان العنوان العنوان</span>
    //         </h5>
    //       </div>
         
    <div className="card" {...onClick}>
        
        {props.image && <img className="card-img-top te-card-img" src={props.image} alt="" />}
        {props.children && props.children}
        <div className="card-body">
            <h4 className="te-card-title">{props.title}{props.clickBtn}</h4>
            <div className="card-text text-center">{props.content}</div>
        </div>
        {
            props.footer &&
            <div className="card-footer">
                {props.footer}
            </div>
        } 
     </div>
    return (

        <div className=" col-6 col-sm-3 col-md-2 m-3  actions">
            {/* <Link to={'/'+props.clickBtn}> */}
            {data}
           
            {/* </Link> */}
        </div>
    );
}

export default CardItem;