import React from 'react'
import { Label } from 'semantic-ui-react';
import ImageInput from './ImageInput';
import MapInput from './MapInput';
import LifeSelected from './LifeSelected';
import FileInput from './FileInput';
import numberPro from '../../pro/numberPro';

export const FormControll = (props) => {


    let { life, selectText, lifeFields, selectSh, label, type, placeholder, className, itemID, status, value, showInAdd, showInEdit, selectIndex, fromTable, pageType, one, processType } = props;
    let labelCaption = '';


    if (value === 'null' || value === 'undefined')
        value = '';

    let selectId = '';

    let selectItem = '';
    // if (type !== "hidden") {//
    labelCaption = <Label pointing='below'>{label}</Label>;
    // }

    if (pageType === 'addNew') {
        value = '';
    }
    if (showInAdd === 2 && processType === 'insert') {
        return <div />
    }
    else if (showInEdit === 2 && processType !== 'insert') {
        return <div />
    }
    else if (type === "textarea") {
        return (
            <div className="form-group col-12 col-md-6">
                <Label pointing='below'>{label}</Label>

                <textarea
                    type={type}
                    className={"form-control " + className}
                    itemID={itemID}
                    id={itemID}
                    placeholder={placeholder}
                    ref={ref => {
                        props.parent.inputs[itemID] = ref;
                        props.parent.fieldValues[itemID] = '';
                    }}
                    defaultValue={value}
                />
                <div className="text-danger">{status}</div>
            </div>

        )
    } else
        if (fromTable && life === 1) {
            return (
                <div className="form-group col-12 col-md-6">
                    <LifeSelected
                        status={status}
                        labelCaption={labelCaption}
                        value={value}
                        placeholder={placeholder}
                        itemID={itemID}
                        id={itemID}
                        fromTable={fromTable}
                        lifeFields={lifeFields}
                        onSelect={(value) => {
                            props.parent.setLifeSelect(itemID, value)
                        }}
                    />
                </div>


            )
        }
        else
            if (type === "select") {
                let selectItemOb = [];

                if (fromTable) {
                    selectItem = localStorage.getItem("getUpdateData" + fromTable);
                    selectItem = JSON.parse(selectItem);
                    if (one) {
                        let it = { id: 0, name: one }
                        selectItem.unshift(it)
                    }

                    let name = '';
                    if (!selectId)
                        selectId = 'id'
                    if (!name)
                        name = 'name';



                    for (let i in selectItem) {


                        if (selectSh) {
                            let selectTex = selectText
                            for (let j in selectSh) {

                                name = selectItem[i][selectSh[j]]

                                selectTex = selectTex.replace(selectSh[j], name)

                            }
                            name = selectTex
                        }
                        else
                            name = selectItem[i]['name']

                        let itm = { 'id': selectItem[i][selectId], 'name': name }

                        selectItemOb.push(itm);
                    }

                } else {
                    if (props.selectedData[selectIndex]) {
                        selectItem = props.selectedData[selectIndex][itemID];

                        for (let i in selectItem) {
                            let itm = { 'id': i, 'name': selectItem[i] }
                            selectItemOb.push(itm);
                        }
                    }

                }

                return (
                    <div className="form-group col-12 col-md-6">
                        <Label pointing='below'>{label}</Label>

                        <select
                            className={"form-control " + className}
                            itemID={itemID}
                            id={itemID}
                            placeholder={placeholder}
                            ref={ref => {
                                props.parent.inputs[itemID] = ref;
                                props.parent.fieldValues[itemID] = '';
                            }}
                            defaultValue={value}
                        >

                            {selectItemOb.map((selectItemOb, i) => {
                                let idid = i
                                if (i === 0)
                                    idid = itemID
                                return <option id={`_${idid}`} value={selectItemOb['id']} key={i}>{selectItemOb['name']}</option>
                            }
                            )}
                        </select>
                        <div className="text-danger">{status}</div>
                    </div>

                )
            } else if (type === "image" || type === 'files') {
                return <ImageInput
                    onImageSelect={(url) => {
                        props.parent.setImage(itemID, url)
                    }}
                    label={label}

                />
            } else if (type === "file") {
                return <FileInput
                    onUpload={(url) => {
                        props.parent.setField(itemID, url)
                    }}
                />
            } else if (type === "location") {
                return <MapInput
                    onSelect={(location) => {
                        props.parent.setLocation(itemID, location)

                    }}
                    defaultValue={value}
                />
            } else
                if (type === 'countable') {

                    let value2 = numberPro.dotted(value)
                    let TF = numberPro.tafgeet(value)
                    const setDottet = (v, itemID) => {
                        v = v.split(',');
                        v = v.join('');
                        document.getElementById(itemID).value = v;
                        let TF = ''
                        if (v) {

                            TF = numberPro.tafgeet(v)
                            TF = `[${TF}]`
                        }
                        document.getElementById('TF' + itemID).innerHTML = TF;
                        v = numberPro.dotted(v)
                        document.getElementById(itemID).value = v;
                    }
                    return (
                        <div className="form-group col-12 col-md-6">
                            {labelCaption} <span id={'TF' + itemID}>{TF}</span>
                            {/* <input
                                type={'hidden'}
                                className={"form-control " + className}
                                itemID={itemID}
                                id={itemID}
                                placeholder={placeholder}
                                defaultValue={value}
                                ref={ref => {

                                    props.parent.inputs[itemID] = ref;
                                    props.parent.fieldValues[itemID] = '';
                                }}
                            /> */}
                            <input
                                type={'text'}
                                className={"form-control " + className}
                                itemID={itemID}
                                id={itemID}
                                placeholder={placeholder}
                                onKeyDown={(event) => {
                                    let chr = event.keyCode;
                                    console.log(chr);
                                    let chrCode = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57]

                                    let chr2 = chrCode.indexOf(chr)
                                    if (chr !== 8 && chr !== 39 && chr !== 37 && chr !== 35 && chr !== 36)
                                        event.preventDefault();
                                    if (chrCode.indexOf(chr) !== -1) {


                                        let v = document.getElementById(itemID).value
                                        v = v + '' + chr2
                                        setDottet(v, itemID)

                                    }

                                }}
                                onKeyUp={(e) => {
                                    if (e.keyCode === 8) {
                                        let v = document.getElementById( itemID).value
                                        setDottet(v, itemID)
                                    }
                                }}
                                onChange={() => {
                                    let v = document.getElementById(itemID).value
                                    setDottet(v, itemID)
                                }}

                                defaultValue={value2}

                                ref={ref => {

                                    props.parent.inputs[itemID] = ref;
                                    props.parent.fieldValues[itemID] = '';
                                }}

                            />
                            <div className="text-danger">{status}</div>
                        </div>
                    )
                }
                else {
                    return (
                        <div className="form-group col-12 col-md-6">
                            {labelCaption}

                            <input
                                type={type}

                                className={"form-control " + className}
                                itemID={itemID}
                                id={itemID}
                                placeholder={placeholder}
                                ref={ref => {
                                    props.parent.inputs[itemID] = ref;
                                    props.parent.fieldValues[itemID] = '';
                                }}
                                defaultValue={value}


                            />
                            <div className="text-danger">{status}</div>
                        </div>
                    )
                }
}