import { appTypes } from "./actionTypes";

export const changeAppLang = (lang) => dispatch => {
    dispatch({ type: appTypes.changeLang, values: { lang } });
}
export const setTableDetails = (tableDetails) => dispatch => {
    dispatch({ type: appTypes.setTableDetails, values: { tableDetails } });
}

export const setStateValues = (values) => dispatch => {
    dispatch({ type: appTypes.setStateValues, values });
}
