import React from "react";
import { connect } from 'react-redux'
import Base from "../pro/Base";


import { LoginWithEmail } from "./states/actions/loginActions";
import Axios from "axios";
import '../css/login.css'
import { Link } from "react-router-dom";
import { SUBFOLDER } from "./local/config";
import { string } from "./lang";

class Login extends Base {
    constructor(props) {
        super(props)
        this.state = {
            status: "",
            connected: false,
            loading: false,
            img: <img src={require('../images/loading.gif')} alt='loding' style={{ width: 40 }} />
        }
    }
    componentDidMount() {
        super.componentDidMount();
    }

    loginUser = async (event) => {
        event.preventDefault();
        this.setState({ loading: true, })
        this.changeState("status", string("connecting"));

        this.refs.status.style.color = "red";
        let username = this.username.value;
        let password = this.password.value;
        if (password === "" || username === "") {
            this.changeState("status", string("noEmptyField"));
        }
        else {
            this.changeState("status", this.state.img);
            let type = "email"
            let data = JSON.stringify({
                email: this.username.value,
                password: this.password.value
            });

            let response = await Axios.post(`${this.url}/login/${type}`, { data: data }).then(response => response)
                .catch((error) => 'error');

            if (response === 'error') {

            } else {

                response = response.data

                if (response.error === false) {
                    this.refs.status.style.color = "green";
                    this.username.value = "";
                    this.password.value = "";

                    localStorage.setItem(this.token_name, response.accessToken);
                    this.changeState("status", string("checked"));

                    await this.props.LoginWithEmail({
                        user: response.user,
                        accessToken: response.accessToken,
                        type: "email"
                    });
                    window.location = SUBFOLDER
                    // this.props.page.setState({ hasToken: true});               
                }
                else {
                    this.changeState("status", string("inValidLogin"));
                    this.changeState("status", response.msg)
                }
            }
        }
        this.setState({ loading: false, })
    }
    render() {
        return <div className="login-parent body">
            <div className="form-wrap">
                <div className="form">
                    <h2>
                        <img src={require('../images/logo.png')} alt=""  style={{width:'350px'}}/>
                    </h2>
                    <h4>{string('loginWelcome')}</h4>
                    <form onSubmit={this.loginUser}>
                        <div className="field">
                            <i className="fal fa-envelope "></i>
                            <input
                                type="text"
                                name=""
                                id=""
                                placeholder={string("email")}
                                ref={(ref) => this.username = ref}
                                onChange={() => this.emptyStatus()}
                            />
                        </div>

                        <div className="field">
                            <i className="fal fa-lock"></i>
                            <input type="password" name=""
                                ref={(ref) => this.password = ref}
                                onChange={() => this.emptyStatus()}
                                id=""
                                placeholder={string("password")}
                            />
                        </div>

                        <div className="lower" style={{ textAlign: "center", display: "block" }}>
                            {/* <div className="checkboxOverride">
                            <input
                                type="checkbox"
                                name=""
                                id="checkboxInputOverride"
                                value="1"
                            />
                            <label for="checkboxInputOverride" className="label-text">
                                <span>
                                    تذكرني
             </span>
                            </label>
                        </div> */}
                            <Link to="forgat-password">{string("forgetPassword")}</Link>
                        </div>
                        <div style={{ color: "red", textAlign: 'center' }} ref="status" className="error-status">{this.state.status}
                        </div>
                        <div className="text-center">

                            <a href="/" onClick={this.loginUser}><button  className="" style={{ background: "transparent", border: "transparent" }}>{string("login")} </button></a>

                        </div>
                    </form>
                </div>
                <div className="text-padding">
                    {/* <a href="sign.html" className="new">هل انت جديد؟<span> سجل الان</span></a> */}
                </div>
            </div>
        </div>
    }
}
const stateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn
    }
}

export default connect(stateToProps, { LoginWithEmail })(Login);