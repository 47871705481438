import React from 'react'
import { connect } from "react-redux";
import Base from './Base';
import Page from './Page';
import { Table } from 'react-bootstrap';

import { TableNotFound } from './TablesError';
// import queryString from 'query-string';
import { Button, Icon, Modal, Label } from 'semantic-ui-react';
import Form from '../component/input/Form';
import { string } from '../app/lang';
import { Fade } from "react-reveal";

class AddNew extends Base {
    constructor(props) {

        super(props)
        this.page = this.props.match.params.page;
        this.forgeinkey = this.queryString()

        this.state = {
            ...this.state,
            showModal: true,
            saved: false,
            fieldsCount: 1,
            inputFields: [],
            inputKeys: [],
            status: '',
            inputError: null,
            saving: false,
            generateFields: false
        }
    }
    async componentDidMount() {
        super.componentDidMount();

    }
    configForm = () => {
        this.setState({ generateFields: true })
        if (this.props.tables[this.page]) {
            let fieldsCount = this.state.fieldsCount
            if (fieldsCount >= 1) {
                // Get Fields Keys 
                let keys = {};
                let inputKeys = [];
                let fields = this.props.tables[this.page].fields
                for (let key in fields) {
                    keys[fields[key].itemID] = ""
                    inputKeys.push(fields[key])
                }

                //تولد حقول الجدول
                let inputFields = [];
                for (let index = 0; index < fieldsCount; index++) {
                    inputFields.push(keys)
                }
                this.setState({
                    fieldsCount,
                    inputFields,
                    inputKeys,
                    generateFields: false, showModal: false
                })
            }
        }
    }
    setInputValue = (event, index, key) => {
        event.preventDefault();
        let inputFields = this.state.inputFields;
        let inputValue = event.target.value
        inputFields[index] = {
            ...inputFields[index],
            [key]: inputValue
        }
        this.setState({
            inputFields
        })
        console.log({ inputFields, index, key });
    }
    saveData = async () => {
        let status = "";

        this.setState({ status, inputError: null, })
        let { inputFields } = this.state;
        let inputError = false
        for (let index = 0; index < inputFields.length; index++) {
            const row = inputFields[index];
            for (let rowElement in row) {
                if (row[rowElement] === "") {
                    inputError = `${index}-${rowElement}`;
                    break
                }
            }
            if (inputError) {
                this.setState({
                    status: 'الرجاء ملء كل الحقول',
                    inputError
                })
                return
            }
        }
        let saved = false
       
        this.setState({ saving: true, })
        await this.insertItem(this.page, {
            inputFields,
            type: "multi",
        })
        if (this.state.insert.error === false) {
            status = "Insert is Completed";
            this._status.style.color = 'green'
            saved = true
        }
        this.setState({
            saving: false,
            saved,
            status
        })
    }

    render() {
        let pageData ='';
        let { inputFields, inputKeys, status, inputError, saving } = this.state
        let { tables } = this.props;
        let currentTableFields = []

        if (!tables[this.page]) {
            pageData = <TableNotFound />
        } else {
            currentTableFields = tables[this.page]?.fields

            if (tables[this.page].fields) {
                this.fields = tables[this.page].fields;
                // this.page = tables[this.page].page;

                let submit = { value: "send", type: "insert", page: this.page }
                pageData = <Form fields={this.fields} pageType={'addNew'}
                    submit={submit} forgeinkey={this.forgeinkey} />
            } else {
                pageData = string("connectionError")
            }
        }

        return (
            <div><div style={{disply:'none'}}>{pageData}</div>
                <Page>
                    <section>
                        <div className="background"></div>
                        <div className="table-wrapper" style={{ position: "relative", padding: 10 }}>
                            <div style={{ padding: 5, textAlign: "left" }}>
                                <Button color="blue" onClick={this.goBack}>{string("goBack")}</Button>
                            </div>
                            <div ref={ref => this._status = ref} style={{ textAlign: 'center', color: "#e45", fontSize: 20 }}>{status}</div>

                            <Fade when={!this.state.saved}>
                                <Table striped bordered condensed hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ width: 15 }}>#</th>
                                            {
                                                currentTableFields.map((item, index) => {

                                                    return <th>{item.label}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            inputFields.map((item, index) => {
                                                return <tr>
                                                    <td style={{ width: 12, padding: 5, }}>{index * 1 + 1}</td>
                                                    {
                                                        inputKeys.map((inputItem, inputIndex) => {
                                                            // let itemIndex = `${index}-${inputIndex}`
                                                            return <td style={{ width: 150, padding: 5 }}>
                                                                <input
                                                                    className="form-control"
                                                                    placeholder={`أدخل ${inputItem.label}`}
                                                                    value={inputFields[inputItem.itemID]}
                                                                    style={inputError === `${index}-${inputItem.itemID}` ? {
                                                                        width: "100%", minWidth: 150,
                                                                        border: "1px solid #e45"
                                                                    } : { width: "100%", minWidth: 150, }}
                                                                    onChange={(e) => this.setInputValue(e, index, inputItem.itemID)}
                                                                />
                                                            </td>
                                                        })
                                                    }
                                                </tr>
                                            })
                                        }
                                    </tbody>

                                </Table>
                                <div>
                                    <Button
                                        color="green"
                                        onClick={this.saveData}
                                        disabled={saving}
                                        loading={saving}
                                    >
                                        <span>حفظ</span>
                                        <Icon name="save outline" />
                                    </Button>
                                </div>
                            </Fade>
                        </div>
                    </section>
                    <Modal
                        open={this.state.showModal}
                        size="tiny"
                        centered={false}
                    >
                        <Modal.Header>إدخال عدد من السجلات</Modal.Header>
                        <Modal.Content>
                            <div className="form-group">
                                <Label circular pointing="below">عدد السجلات المراد أدخالها؟</Label>
                                <input
                                    className="form-control col-sm-12 "
                                    placeholder={`أدخل عدد السجلات`}
                                    type="number"
                                    value={this.state.fieldsCount}
                                    style={{
                                    }}
                                    onChange={(e) => this.setState({ fieldsCount: e.target.value })}
                                />
                            </div>
                        </Modal.Content>
                        <Modal.Actions>

                            <Button
                                loading={this.state.generateFields}
                                onClick={this.configForm} positive>
                                متابعة
              </Button>
                        </Modal.Actions>
                    </Modal>
                </Page>
            </div>
        );
    }

}
const getState = (state) => {
    return {
        accessToken: state.auth.accessToken,
        isLoggedIn: state.auth.isLoggedIn,
        checking: state.auth.checking,
        tableDetails: state.app.tableDetails,
        tables: state.app.tableDetails.tables,
    }
}

export default connect(getState, {})(AddNew);
