import {combineReducers} from "redux";
import loginReducer from "./loginReducer";
import chatReducer from "./chatReducer";
import dbReducer from "./dbReducer";
import appReducer from "./appReducer";

export default combineReducers({
    auth: loginReducer,
    chat: chatReducer,
    db: dbReducer,
    app: appReducer,
});