import React from 'react'
import { LogOut as logUserOUt } from '../app/states/actions/loginActions';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import Base from '../pro/Base';
import { Icon } from 'semantic-ui-react';

class LogOut extends Base {
    onLogOut = (event) => {
        event.preventDefault();
        let yes = window.confirm("هل تريد الخروج حقا؟");
        if (yes) {
            this.props.logUserOUt();
        } else {
            this.changeState("visible", false)
        }
    }
    render() {
        return (
            <li>
                <Link to={"/home"} className="subMenuLink" onClick={this.onLogOut}>
                    <Icon name={"sign-out alternate"} color="orange" />
                    <span style={{margin: 14}}>خروج</span>
                    <div className="loading"><this.mLoading /></div>
                </Link>

            </li>

        )
    }
}

export default connect(null, { logUserOUt })(LogOut);