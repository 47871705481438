import React from 'react'
import { connect } from 'react-redux'
import Base from '../../../pro/Base';


class UploadImage extends Base {
    constructor(props) {
        super(props);
        this.tableName = "H-1700";
        // this.serviceId = this.props.match.params.id

        this.page = "H-1700";
        this.state = {
            ...this.state,
            loading: true,
            images: [],
            imageUri: URL.createObjectURL(this.props.image),
            imageData: this.props.image,
            imageOpacity: 1,
            catIndex: 0,
        }
        this.user = this.props.user;
        this.conditions = {
        }
    }

    async componentDidMount() {
        super.componentDidMount();
        if (this.props.file) {
            this.tableName = "H-1800"
            this.page = "H-1800"
        }
        // await this.findOne();
        this.Upload();
    }
    Upload = async () => {
        let image = this.state.imageData

        await this.upload(this.page, "new", {}, image)

        if (this.state.upload.error) {
            this.changeState("error", true);
        } else {
            let { url } = this.state.upload.newRow
            this.props.onUploadFinish(url);


        }
        this.changeState("loading", false);
        this.changeState("loaded", true);
    }

    render() {

        let { imageOpacity, imageUri } = this.state;
        let image = <img src={imageUri} alt="" style={{ width: 60, height: 60, opacity: imageOpacity }} />;
        if (this.state.loading) {
            image = require("../../../images/loading.gif");
            // image = <img src={image} alt="" style={{ width: 120, height: 150, opacity: imageOpacity }} />
            image = <img src={image} alt="" style={{ width: 30, height: 30, opacity: imageOpacity }} />
        }
        if (!this.state.loading && this.state.error) {
            image = "حدث خطأ"
        }

        return (
            <>
                <div className="col-" style={{
                    margin: 2, display: "flex",
                    background: "#ccc",
                    wordBreak: "break-all",
                }}>
                    {
                        this.state.loaded &&
                        <div style={{ background: '#f4f0f099', color: "red", cursor: "pointer", width: 60, textAlign: 'center' }} onClick={this.props.onRemoveImage}>x</div>
                    }
                    {
                        this.props.file ?
                            <div>{this.state.imageData.name}</div>
                            :
                            image
                    }
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        accessToken: state.auth.accessToken,
        user: state.auth.user,
    }
}
export default connect(mapStateToProps, {})(UploadImage);