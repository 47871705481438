import React from 'react'
import { connect } from 'react-redux'
import Base from '../../pro/Base';
import { NotLoggedIn } from '../security/Notes';

import { FormSubmit } from './FormSubmit';
import { FormControll } from './FormControll';
import { string } from '../../app/lang';
import { Modal, Button } from 'semantic-ui-react';

class Form extends Base {
    constructor(props) {
        super(props);
        this.state = {
            ...this.state,
            fields: this.props.fields,
            submitDisabled: false,
            dataSaved: false,
            message: "",
        }

        this.inputs = []
        this.fieldValues = {}
        this.send = this.send.bind(this);
        this.submit = props.submit;
        //  this.selectItem
        if (this.submit.type === 'insert') {
            this.action = this.insertItem;
        } else if (this.submit.type === 'edit') {
            this.action = this.editItem;
        } else if (this.submit.type === 'delete') {
            this.action = this.deleteItem;
        }
    }
    /**
     *تخزين قيم الحقول في مصفوفة  
     */
    getFieldVal = () => {
        for (let i in this.inputs) {
            this.fieldValues[i] = `${this.inputs[i].value}`.toString().trim()
        }
    }

    // التحقق مما إذا كانت قيم الحقول فارغة
    isEmpty = (array) => {
        let empty = false;
        let fields = this.props.fields;

        for (let i in fields) {
            if (fields[i].isEmpty === 1 || fields[i].showInAdd === '2') {
                fields[i].status = '';
                continue
            }
            if (this.fieldValues[fields[i].itemID] === "") {
                empty = true;
                let currentField = fields[i];

                fields[i].status = <span><span style={{ color: 'blue' }}>({currentField.label}) </span> لا يمكن أن يكون فارغاً</span>;

                this.changeState("fields", fields);
                // break;
            } else {
                fields[i].status = '';
            }

        }


        return empty;
    }
    setImage = (itemID, url) => {
        this.inputs[itemID] = {
            value: url
        }

    }
    setLocation = (itemID, location) => {
        this.inputs[itemID] = {
            value: location
        }
    }
    setLifeSelect = (field, value) => {
        this.inputs[field] = {
            value: value
        }

    }
    setField = (fieldName, url) => {
        this.inputs[fieldName] = {
            value: url
        }
    }
    send = async (event) => {
        event.preventDefault();
        this.changeState('submitDisabled', true)
        this.getFieldVal();

        if (!this.isEmpty(this.fieldValues)) {


            let fields2 = this.props.fields;
            let dataSet = this.fieldValues
            for (let i in fields2) {
              dataSet[fields2[i].itemID] = this.fieldValues[fields2[i].itemID]
              if(fields2[i].type==='countable')
              {
                  dataSet[fields2[i].itemID] =  this.fieldValues[fields2[i].itemID].replaceAll(',','')

              }

            }
            const fields = {  ...dataSet , ...this.props.forgeinkey }



            await this.action(this.props.submit.page, fields, this.props.updateKey);
            if (this.props.onFinish) {
        

                this.props.onFinish(fields)
            }
            let message = ""
            const submitType = this.props.submit.type;

            if (this.state.c_status === "connecting") {
                message = <this.Loading />
            } else if (this.state.c_status === 'connectionError') {
                message = string("connectionError")
            } else if (this.state.c_status === 'connected') {
                let submitRes = this.state[submitType];
                if (submitRes.error === false) {
                    if (this.refs.statusMsg) {
                        this.refs.statusMsg.className = 'alert text-success'
                    }
                    if (this.refs.form) {
                        console.log(this.props);
                        let f = this.state.fields
                        f.forEach(element => {

                            if (document.getElementById('_' + element.itemID)) {
                                document.getElementById(element.itemID).value = document.getElementById('_' + element.itemID).value

                            }
                            else
                                if (document.getElementById(element.itemID)) {
                                    console.log(document.getElementById(element.itemID));
                                    document.getElementById(element.itemID).value = ''
                                    // document.getElementById(element.itemID).innerHTML = ''
                                }
                        });
                        if (this.submit.type === 'edit')
                            this.refs.form.style.display = 'none'
                    }
                    setTimeout(() => {
                        if (this.submit.type === 'edit') {
                            this.closeForm();
                        }
                    }, 1500);
                    this.setState({ dataSaved: true })
                    message = string("dataSaved")
                } else if (submitRes.error === true) {
                    if (submitRes.errorType === 'userValidation') {
                        message = <NotLoggedIn />
                    }
                }
            }

            if (this.state.c_status === 'error') {
                message = this.state.PAGE_ERROR
            }
            this.setState({ message })
        }
        this.changeState('submitDisabled', false)
    }
    closeForm = () => {
        this.setState({ message: "" })
        this.props.onClose();
    }
    render() {
        this.getToken();


        if (this.TOKEN_VALUE === 404) {

            return <NotLoggedIn />
        }
        let { fields } = this.props;
        const { submit } = this.props;
        let msg = this.state.message;
        if (submit.type === "insert") {
            submit.onClick = () => this.insertItem(submit.page)
        } else if (submit.type === "update") {
            submit.onClick = () => this.update()
        }


        let formDetails = this.props.formDetails ? this.props.formDetails : {}
        if (this.state.insert.error === false) {

        }


        return (

            <div style={{}}>
                {
                    this.submit.type === "insert" ?
                        <>
                            <div style={{ margin: 10, textAlign: "center" }} className="text-danger" ref='statusMsg'>
                                {msg}
                            </div>
                            <form method="post" encType="multipart/form-data" ref="form">
                                <div className='row'>
                                    {
                                        fields.map((field, i) => <FormControll
                                            selectedData={this.props.tables.selectedData}
                                            processType={this.props.submit.type}
                                            key={i} itemID={i} {...field} parent={this} pageType={this.props.pageType} />)
                                    }

                                    <div>
                                        <br />

                                    </div>
                                </div>
                            </form>
                            <FormSubmit
                                loading={this.state.submitDisabled}
                                {...submit} parent={this} onClick={this.send}
                                disabled={this.state.submitDisabled}
                                value={string("accept")} />

                        </>
                        :
                        <Modal
                            size="fullscreen"
                            // dimmer={"blurring"}
                            open={this.props.open}

                            onClose={this.props.onClose}>
                            <Modal.Header>
                                {
                                    formDetails.editTitle ?
                                        <span>{formDetails.editTitle}</span>
                                        :
                                        <span> تعديل البيانات</span>
                                }


                            </Modal.Header>
                            <Modal.Content scrolling>
                                <div className="col-md-12">
                                    <div style={{ margin: 10, textAlign: "center" }} className="text-danger" ref='statusMsg'>
                                        {msg}
                                    </div>
                                    <form method="post" encType="multipart/form-data"
                                        ref="form"
                                        className="row"
                                        style={{ width: "100%" }}
                                    >
                                        {
                                            fields.map((field, i) => <FormControll
                                                selectedData={this.props.tables.selectedData}
                                                key={i} itemID={i} {...field} parent={this} pageType={this.props.pageType} />
                                            )
                                        }
                                    </form>
                                </div>
                            </Modal.Content>
                            <Modal.Actions>
                                {
                                    !this.state.dataSaved &&
                                    <>
                                        <Button
                                            positive
                                            icon='checkmark'
                                            labelPosition='left'
                                            content="حفظ"
                                            onClick={this.send}
                                            loading={this.state.isLoading}
                                            disabled={this.state.isLoading}
                                        />
                                        <Button color='black' onClick={this.closeForm}>
                                            <span>إغلاق</span>
                                        </Button>
                                    </>
                                }
                            </Modal.Actions>
                        </Modal>
                }
            </div>
        )
    }
}

const stateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        accessToken: state.auth.accessToken,
        user: state.auth.user,
        tables: state.app.tableDetails,
    }
}

export default connect(stateToProps)(Form);