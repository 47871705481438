import React from 'react'
import { Icon, Confirm, } from 'semantic-ui-react'
import { connect } from 'react-redux'
import Base from '../../pro/Base';
import Form from './Form'
import { string } from '../../app/lang';

class EditFrom extends Base {
    constructor(props) {
        super(props)
        this.parent = this.props.parent;

        this.page = this.parent.page
        this.tables = this.props.tables;
        this.fields = []
        if (this.tables[this.page]) {
            this.fields = this.tables[this.page].fields
        }
        this.inputs = {};
        this.state = {
            ...this.state,
            showConfirm: false,
            currentId: null,
            currentIndex: null,
            showUpdateForm: false,
            updateItem: {},
            status: '',
            fields: {},
            formFields: [],
            processType:'edit',
            updateSubmit: { value: string("accept"), type: "edit", page: this.page }
        }
    }
    componentDidMount() {
        super.componentDidMount();
        this.generateFields()
        // $this = this.
    }

    toggleForm = () => {
        this.setState({ showConfirm: !this.state.showConfirm })
    }
    _onDelete = () => {
        let id = this.props.values.id;
        let index = this.props.index;

        this.setState({
            currentId: id,
            currentIndex: index,
            showConfirm: true
        })
    }
    doDelete = async () => {
        let id = this.state.currentId;
        let index = this.state.currentIndex;

        if (id && index + 1) {
            this.deleteItem(this.page, { id })
            if (this.state.deleted.error) {

            } else {
                let parent = this.props.parent
                if (this.props.mainParent) {
                    parent = this.props.mainParent
                }
                let table = parent.state.table;
                table.splice(index, 1);
                parent.setState({ table })
                this.toggleForm();
            }
        }
    }
    /**
     * تخذين أسماء الحقول في كائن واحد لكي يسهل التعامل معها 
     * {key1: "", key2: ""}
     */
    generateFields = () => {
        let fields = {};
        let formFields = this.fields;

        for (let index = 0; index < formFields.length; index++) {
            const element = formFields[index];
            formFields[index].value = this.props.values[element.itemID]
        }
        this.fields = formFields

        for (let key in this.props.values) {
            if (this.props.cut.includes(key)) {
                continue;
            }
            fields[key] = "";
        }
        this.setState({ fields, formFields })
    }
    // التحقق من الحقول مما إذا كانت فارغة
    checkEmptyFields = () => {
        let empty = false;

        for (let key in this.state.fields) {
            if (this.state.fields[key] === "") {
                empty = true;
                break;
            }
        }
        return empty;
    }
    /**
     * تخذين قيمة الحقول من المتغير 
     * this.input 
     * في المتغير 
     * this.state.fields
     */
    setFieldsValues = () => {
        let fields = {};
        for (let key in this.state.fields) {
            const element = this.inputs[key];
            if (element.value) {
                fields[key] = `${element.value}`.toString().trim()
            } else {
                fields[key] = ""
            }
        }
        this.setState({ fields })

    }
    save = async () => {
        await this.setFieldsValues();
        let { fields } = this.state;
        let hasEmptyFields = await this.checkEmptyFields();

        this.setState({ isLoading: true })
        if (!hasEmptyFields) {
            let id = this.props.values.id;

            await this.editItem(this.page, fields, { id });
            if (this.state.edit.error === false) {
                let table = this.parent.state.table;
                let index = this.props.index;
                table[index] = {
                    ...table[index],
                    ...fields,
                }
                this.parent.setState({ table })
                this.setState({ showUpdateForm: false })
            }
        } else {
            this.setState({ status: 'الرجاء تعبئة كل الحقول' })
        }
        this.setState({ isLoading: false })

    }
    openForm = (item) => {
        let { index, values } = this.props

        this.setState({
            updateItem: values,
            currentId: values.id,
            currentIndex: index,
            showUpdateForm: true
        })
        this.generateFields();
        document.onkeydown = (event) => {
            if (event.which === 27) {
                this.setState({ showUpdateForm: false })
            }
        }
    }
    toggleUpdateFrom = () => {
        this.setState({ showUpdateForm: !this.state.showUpdateForm })
    }
    updateFinished = (field, index) => {
        let parent = this.props.parent
        if (this.props.mainParent) {
            parent = this.props.mainParent
        }
        let newTable = parent.state.table;
        let currentField = newTable[index];
        currentField = { ...currentField, ...field }

        newTable[index] = currentField;
        parent.setState({ table: newTable })
        this.generateFields()
    }
    close = () => {
        this.toggleUpdateFrom();
    }
    render() {
        let tables = this.props.tableDetails.tables
        let selectedData = tables.selectedData;
        // return <div>testing</div>

        return (
            <>
                <div className="actions-wrapp">
                    {
                        // this.props.delete &&
                        <Icon name="remove" className="remove"
                            onClick={this._onDelete}
                        />
                    }
                    {
                        this.props.edit &&
                        <Icon name="edit outline" className="edit"
                            onClick={this.openForm}
                        />
                    }
                </div>
                <Confirm
                    open={this.state.showConfirm}
                    header='سيتم حذف هذا العنصر من النظام'
                    content="هل تريد الإستمرار؟"
                    onCancel={this.toggleForm}
                    onConfirm={this.doDelete}
                />

                <Form
                    ref={ref => this._form = ref}
                    parent={this.props.parent}
                    open={this.state.showUpdateForm}
                    onClose={this.close}
                    selectedData={selectedData}
                    fields={this.state.formFields}
                    submit={this.state.updateSubmit}
                    updateKey={{ id: this.props.values.id }}
                    onFinish={(field) => this.updateFinished(field, this.props.index)}
                />
                {/* <Modal
                    // dimmer={"blurring"}
                    open={this.state.showUpdateForm} onClose={this.close}>
                    <Modal.Header>تعديل البيانات</Modal.Header>
                    <Modal.Content image>
                        <form className="col-md-12">
                            <div style={{ color: "red", textAlign: 'center' }}>{this.state.status}</div>

                            {
                                // !this.fields[0] ?
                                //     <h3 className="alert alert-danger">Error, Check Your Connection</h3>
                                //     :
                                //     this.fields.map((item, index) => {
                                //         // if (item.type ==="textarea") {
                                //         return <FormControll
                                //             selectedData={selectedData}

                                //             key={index}
                                //             itemID={index}
                                //             {...this.fields} value=
                                //             {this.props.values[item.itemID]}
                                //             parent={this} pageType={'edit'}
                                //         />

                                // <div key={index} className="form-group">
                                //     <Label pointing='below'>{item.label}</Label>
                                //     <textarea
                                //         type={item.type}
                                //         placeholder={item.placeholder}
                                //         defaultValue={this.props.values[item.itemID]}
                                //         className="form-control"
                                //         ref={ref => this.inputs[item.itemID] = ref}
                                //     />
                                // </div>
                                // }
                                //             return <div key={index} className="form-group">
                                //                 <Label pointing='below'>{item.label}</Label>
                                //                 <input
                                //                     type={item.type}
                                //                     placeholder={item.placeholder}
                                //                     defaultValue={this.props.values[item.itemID]}
                                //                     className="form-control"
                                //                     ref={ref => this.inputs[item.itemID] = ref}
                                //                 />
                                //             </div>
                                //         })

                            }


                        </form>

                    </Modal.Content>
                    <Modal.Actions>
                        {/* <Button
                            positive
                            icon='checkmark'
                            labelPosition='left'
                            content="حفظ"
                            onClick={this.save}
                            loading={this.state.isLoading}
                            disabled={this.state.isLoading}
                        /> */}
                {/* <Button color='black' onClick={this.toggleUpdateFrom}>
                            <span>إغلاق</span>
                        </Button>
                    </Modal.Actions>
                </Modal> */}

            </>
        )
    }
}


const getState = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        checking: state.auth.checking,
        tableDetails: state.app.tableDetails,
        tables: state.app.tableDetails.tables,
    }
}

export default connect(getState, {})(EditFrom);
