import React from 'react'
// import Container from "../../component/Container";

// import NavTop from "../../component/Nav";
import Menu from '../app/local/Menu';
import Nav from '../component/Nav';
import Pager from '../app/Pager';

// import ClearCache from "react-clear-cache";

const Page = (props) => {
  let subProps = {};
  if (props.subTitle) {
    subProps.subTitle = props.subTitle
  }
  return (

    <div>
      {/* <ClearCache> */}
        {/* {({ isLatestVersion, emptyCacheStorage }) => ( */}
          <div>
            {/* {!isLatestVersion && (
              <div>
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault();
                    emptyCacheStorage();
                  }}
                >
                  اضغط للاستمرار
          </a>
              </div>
            )} */}
            {/* {!isLatestVersion && ( */}


            <div className="parent">
              <Menu />

              <Nav  {...subProps}>
                <Pager />

                {props.children}
              </Nav>
            </div>
            {/* )} */}

          </div>
        {/* )} */}

      {/* </ClearCache> */}
      </div>
  );
}

export default Page

