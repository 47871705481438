
     import React from 'react'
import { connect } from 'react-redux'

import Route from '../../pro/Route';
import Connect from '../../component/Connect';

// import { setOnNotification } from '../states/actions/loginActions';

class RafeeqCare extends Route {
  constructor(props) {
    super(props)

    this.page = 'H-5502';
    this.tableName = 'H-5502';
    this.queryString2 = this.queryString();                         
let tables ='';
    if (props.tables)
    tables = props.tables.fields;
    let conditions ={};
    let foreginKey ={};
    if (props.tables)
     tables.forEach(element => {

       if(element.type==='fromQuery' || element.setFilter === 3 || element.setFilter === 2)
         {
         if(this.queryString2[element.itemID])
            conditions[element.itemID]= this.queryString2[element.itemID];
            if(!this.queryString2[element.itemID] && element.type==='fromQuery')
            conditions[element.itemID]= 0;

          

         }
         if(element.type==='fromQuery'){
            if(this.queryString2[element.itemID])
                 foreginKey[element.itemID]= this.queryString2[element.itemID];
                 else
                 foreginKey[element.itemID]= 0
         }

         
        });
        conditions.pageNo=this.queryString2.pageNo
        this.conditions=conditions
        this.foreginKey=foreginKey
        


    this.state = {
      ...this.state,
      c_status: 'connecting',
      url: '',
      visible: false,
      isLoading:false,
    }
    this.cut = 'id,deleted';
 
    this.cut = this.cut.split(',')
  }
  async componentDidMount() {
    super.componentDidMount();
    // this.get()
    this.setState({isLoading: true})
    await this.findAll();
    this.setState({isLoading: false})
  }
  async UNSAFE_componentWillReceiveProps(Props) {
 

    this.setState({isLoading: true})
 this.queryString2 = this.queryString3(Props.location.search);


    let tables =this.props.tables.fields;
    let conditions ={};
    let foreginKey ={};
     tables.forEach(element => {

       if(element.type==='fromQuery' || element.setFilter === 3 || element.setFilter === 2)
         {
        if(this.queryString2[element.itemID])
          conditions[element.itemID]= this.queryString2[element.itemID];
          if(!this.queryString2[element.itemID] && element.type==='fromQuery')
          conditions[element.itemID]= 0;

        

       }
       if(element.type==='fromQuery'){
          if(this.queryString2[element.itemID])
               foreginKey[element.itemID]= this.queryString2[element.itemID];
               else
               foreginKey[element.itemID]= 0
       }
        });

        conditions.pageNo=this.queryString2.pageNo
        this.conditions=conditions
        this.foreginKey=foreginKey
        await this.findAll();
  this.setState({isLoading:false})
  }
  render() {

    let q = this.props.location.search;
    let a =q;
    q=a;
    
    if (this.state.tableDetails){
      
    
    }

    sessionStorage.setItem('page-rafeeqCare','rafeeqCare');
    sessionStorage.setItem('name-rafeeqCare','الرفيق الصحي');
    return (
      <Connect parent={this}>
      {
        this.state.isLoading?
        <this.Loading/>
      :
<div className='upper cards-conrtainer'></div>
      }
      </Connect>
    )

  }
}





const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    accessToken: state.auth.accessToken,
    tables: state.app.tableDetails.tables['H-5502'],
    user: state.auth.user,
  }
}
export default connect(mapStateToProps, {})(RafeeqCare);

