import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react';
import { Table as Table2 } from 'react-bootstrap';




// import "./input.css"


export default class ModelDet extends Component {

    constructor(props) {
        super(props)
    

        this.label = 'عرض تفاصيل';
        this.Th = props.Th
        this.data = props.data
  






        this.state = {

            showLife: false,


        }
    }

    toggleLife = (event) => {
        event.preventDefault();
        this.setState({ showLife: !this.state.showLife })
    }



    render() {

console.log(11111);

        return (
            <div>

                <div className="form-group col-12">
                    {this.labelCaption}

                    <button
                        type={this.type}
                        className={"form-control "}
                        itemID={this.itemID}
                        placeholder={this.placeholder}

                        onClick={this.toggleLife}
    

                    >تفاصيل </button>
                    <div className="text-danger">{this.status}</div>
                </div>

                <Modal
          
                    open={this.state.showLife}

                    onClose={this.toggleMap}>
                    <Modal.Header> {this.label}
                        <div style={{ float: 'left' }}>
                            <Modal.Actions style={{ textAlign: "center" }}>

                                <Button color='black' onClick={this.toggleLife}>
                                    <span>تراجع</span>
                                </Button>
                            </Modal.Actions>

                        </div>
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <section>
                            <div className="table-wrapper" style={{ position: "relative" }}>
                                <Table2 striped bordered condensed hover responsive style={{ background: "white" }}>
                                    <thead>
                                        <th>الحقل</th>
                                        <th>المحتوي</th>

                                    </thead>
                                    <tbody>
                                        {
                                            this.Th.map((data, i) =>
                                                <tr>
                                                    <td>{data.label}</td>
                                                    <td>{this.props.data[i]}</td>
                                                </tr>

                                            )

                                        }
                                    </tbody>
                                </Table2>
                            </div>
                        </section>

                    </Modal.Content>

                </Modal>
            </div>
        )
    }
}