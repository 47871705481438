import React from 'react'
import { connect } from 'react-redux'

import Base from '../pro/Base';
import Page from '../pro/Page';

import Connect from './Connect';
import Table from './grid/Table';

class Users extends Base {
    constructor(props) {
        super(props)
        this.state = {
            ...this.state,
            data: null,
            status: "",
            connected: false,
            c_status: "connecting",
            url: "",
            visible: false
        }
        
        this.tableName = "H-1500"
      //  this.user = this.props.user;

        this.page = this.tableName.page;
         

        this.conditions = { userType: 1 }
        this.cut = ["id", "fb_id", "google_id", "custImage", "image", "password"]
    }
    componentWillUnmount() {
        super.componentWillUnmount();
    }
    componentDidMount() {
        super.componentDidMount();
        this.findAll();
    }
    render() {
        if (this.state.connected === false) {

        }
        else if (this.state.connected === true) {

            return <Page>

            </Page>
        }

        return (
            <Connect parent={this}>
                <Table
                    list={this.state.table}
                    parent={this}
                    cut={this.cut}
                    page={this.page}                   
                    pk="id"
                    actions={{ delete: true, edit: true }}
                    addNew={{ page: this.page, type: '' }}
                />
            </Connect>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        accessToken: state.auth.accessToken,
        user: state.auth.user,
    }
}
export default connect(mapStateToProps, {})(Users);