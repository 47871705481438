import React, { Component } from 'react'
import { Modal, Button } from 'semantic-ui-react';
import { Table as Table2 } from 'react-bootstrap';


import "./input.css"

// var _ = require('lodash');


export default class LifeSelected extends Component {

    constructor(props) {
        super(props)
        this.status = props.status;
        this.labelCaption = props.labelCaption;
        this.type = props.type;
        this.itemID = props.itemID;
        this.placeholder = props.placeholder;
        this.value = props.value;
        this.fromTable = props.fromTable
        this.lifeFields = props.lifeFields





        this.selectItem = localStorage.getItem("getUpdateData" + this.fromTable);
        this.selectItem = this.selectItem = JSON.parse(this.selectItem);
        this.selectItemOb = []

        // console.log(this.selectItem);
        for (let i in this.selectItem) {
            let name = this.selectItem[i]['name']
            let id = this.selectItem[i]['id']



            this.itm = { 'id': this.selectItem[i]['id'] }
            let all = this.lifeFields;
            for (let j in all) {
                let index = all[j].field
                this.itm[index] = this.selectItem[i][index]
            }
            this.selectItemOb.push(this.itm);
            if (this.value * 1 === id * 1) {


                this.nameTxt = name
            }

        }


        this.searchKey = '';


        this.state = {
            zoom: 15,
            showLife: false,
            center: "-0.481747846041145,51.3233379650232",
            selectItem10: [],
            nameTxt: this.nameTxt

        }
    }
    selecLife = (event) => {
        event.preventDefault();
        this.props.onSelect(this.state.center);
        this.toggleMap(event);

    }
    toggleLife = (event) => {
        event.preventDefault();
        this.setState({ showLife: !this.state.showLife })
    }

    refValue = (event, value, name) => {
        event.preventDefault();
        this.props.onSelect(value);
        // this.toggleMap(event);
        this.toggleLife(event);
        this.setState({ 'nameTxt': name })
    }

    search = async (event) => {

        let key = this.searchKey.value;
        let data = this.selectItemOb;

        // console.log(data);

        let selectItem10 = []
        let count = 0;
        for (let i in data) {


            var patt = key;
            let str = data[i].name;
            
            
            
         

            if (str)
                var result = str.match(patt);
            if (result) {


                count++;
                selectItem10.push(data[i]);
            }
            if (count > 9)
                break;
        }
        this.setState({ selectItem10 })



    }




    render() {



        return (
            <div>

                <div className="form-group col-12">
                    {this.labelCaption}

                    <button
                        type={this.type}
                        className={"form-control "}
                        itemID={this.itemID}
                        id={this.itemID}
                        placeholder={this.placeholder}

                        onClick={this.toggleLife}
                    // ref={ref => {
                    //     props.parent.inputs[itemID] = ref;
                    //     props.parent.fieldValues[itemID] = '';
                    // }}
                    // defaultValue={value}


                    >{this.state.nameTxt}</button>
                    <div className="text-danger">{this.status}</div>
                </div>

                <Modal
                    // dimmer={"blurring"}
                    open={this.state.showLife}

                    onClose={this.toggleMap}>
                    <Modal.Header> إبحث عن العنصر </Modal.Header>
                    <Modal.Content>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ width: "80%", height: 300, }}>



                                <input

                                    className={"form-control "}
                                    itemID={'keySerch'}
                                    ref={ref => {
                                        this.searchKey = ref;
                                        // props.parent.fieldValues[itemID] = '';
                                    }}

                                    onKeyUp={this.search}


                                />
                                <Table2 striped bordered condensed hover responsive style={{ background: "white" }}>

                                    <thead>
                                        <tr>
                                            {
                                                this.lifeFields.map((field, i) =>
                                                    <td>{field.label}</td>
                                                )

                                            }
                                            <td>-</td>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {


                                            this.state.selectItem10.map((selectItemOb, i) =>
                                                <tr>
                                                    {
                                                        this.lifeFields.map((field, i) =>
                                                            <td>{selectItemOb[field.field]}</td>
                                                        )
                                                    }
                                                    <td><Button onClick={
                                                        (event) => this.refValue(event, selectItemOb.id, selectItemOb.name)

                                                    }>اعتماد</Button></td>

                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table2>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions style={{ textAlign: "center" }}>

                        <Button color='black' onClick={this.toggleLife}>
                            <span>تراجع</span>
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}