import React from 'react'
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';

import Login from './Login';

import Forget from './auth/Forget';
import ChangePassword from './auth/ChangePassword';
import VerifyAccount from './auth/VerifyAccount';
import { SUBFOLDER } from './local/config';

const NotLoggedInRoutes = (props) => {
    return (
        <Router basename={`${SUBFOLDER}/${props.lang}`}>
            <Switch>
                <Route exact path={`/`} component={Login} />
                <Route exact path={`/forgat-password`} component={Forget} />
                <Route exact path={`/change-password/:token`} component={ChangePassword} />
                <Route exact path={`/verifyAccount/:token`} component={VerifyAccount} />
                {/* <Route exact path={`/signup`} component={Signup} /> */}
                <Route exact path={`/${props.lang}/login`} component={Login} />

                <Route component={Login} />

            </Switch>
        </Router>
    )
}

export default NotLoggedInRoutes
