export const loginTypes = {
    faild: "LOGIN_FAILD",
    notLoggedIn: "LOGIN_NOT_LOGGED_IN",
    logUserIn: "LOG_USER_IN",
    success: "SUCCESS",
    islogedIn: "USER_LOGED_IN",
    loading: "LOGIN_IS_LOADING",

    fbLoging: "FB_LGOING_IN",
    fbLoading: "FB_LOADING",
    saveProfileData: "LOGIN_SAVE_PROFILE_DATA",

    loginDone: "LOGIN_DONE_SUCCESSFULY",
    logOut: "LOGOUT_DONE_SUCCESSFULY",
    checkLoggedIn: "LOGIN_IS_CHECKING",
    hasToken: "LOGIN_HAS_TOKEN",
    
    loginType: "LOGIN_TYPE",
    requesting: "SENDING_LOGIN_REQUEST",
    setTokens: "LOGIN_SET_TOKEN_VALUE",
    error: "LOGIN_ERROR",
}
export const chatTypes = {
    faild: "Chat_SEND_FAILD",
    sent: "Chat_SEND_SUCCESS",
    sending: "Chat_SENDING",
    receveMsg: "Chat_RECEVE_MSG",
    fetchingMsg: "Chat_FETCHING_MSG",
}

export const dbTypes = {
    setMenuLinks: "DB_SET_MENU_LINKS",
}

export const appTypes = {
    changeLang: "KH_APP_CHANGE_LANG",
    setTableDetails: "_APP_SET_TABLE_DETAILS",
    setStateValues: "_APP_SET_DEFAULT_STATE_VALUES",
}

