
import React from 'react';
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import CustCount from '../../component/grid/CustCount';

import Base from '../../pro/Base';
import Page from '../../pro/Page';


class Home extends Base {
    constructor(props) {
        let state = { name: 'ali', age: 33 }
        super(props, state);
    }

    render() {


        let items = this.props.menuLinks;


        return (

            <div>
                <Page>
                    <div className='row' style={{ margin: '0px' , alignItems: 'center' }}>
                        {
                            items.map((item) => {
                                let icon = item.icon
                                let name = item.title
                                let to = item.to
                                let custCount = item.custCount
                                
                                
                                return <>
                                {
                                    to!='/' &&
                                    <div style={{
                                    width: '350px', height: '230px',
                                    paddingTop: '5px', textAlign: 'center',
                                    backgroundColor: '#fff ', margin: '10px', display: 'inline-block',
                                }}>

                                    <Link to={`${to}`} style={{width: '330px', height: '150px',
                                    paddingTop: '0px' , display:'block'}}>
                                        <i className={`fas fa-${icon}`} style={{ fontSize: '60px', margin:'20px' }}></i>
                                        <br></br>
                                        <il>{name}</il>
                                        </Link>
                                            <CustCount  custCount={custCount}></CustCount>
                                                                       
                                </div>
                                // return <GCardItem></GCardItem>
                                    }
                                    </>
                            }

                            )
                        }
                    </div>
                </Page>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.auth.isLoggedIn,
        accessToken: state.auth.accessToken,
        user: state.auth.user,
        menuLinks: state.db.menuLinks,
        langQuery: state.app.langQuery,

    }
}
export default connect(mapStateToProps, {})(Home);


