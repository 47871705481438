
import tafqeet from "./tafgeet";

class NumberPro {

    dotted = (no) => {
        console.log(no);
        if (no === null)
            return ''


        let txt = '';
        let mt = '';
        let m = no.toString().split('.');
        if (m[1])
            mt = '.' + m[1]
        no = txt = m[0]
        let l = no.length
        if (l > 3) {
            let one = no.substr(l - 3, 3)
            let tow = no.substr(0, l - 3)
            console.log(one);
            console.log(tow);
            if (tow.length > 3)
                tow = this.dotted(tow)
            txt = tow + ',' + one
        }
        txt = txt + mt;
        return txt;
    }

    tafgeet = (no) => {

        no = no.split(',')
        no = no.join('')
        return tafqeet(no);
    }

}

const numberPro = new NumberPro()

export default numberPro