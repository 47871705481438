export default {
    translation: {
        appName: "Control Panel",

        //Home Page 
        homePage: "Home page",
        whoWeAre: "Who we are",
        services: "الخدمات",
        projects: "المشاريع",
        offers: "سوق اﻹعلانات",
        welcome: "مرحبا بك في خدمتي",
        addProject: "إضافة مشروع",
        pageSubTitle: "تمتع بأفضل تجربة للعمل الحر لرواد اﻷعمال وقم بعرض خدماتك لتصل إلى أكبر عدد من المشتركين",
        startNow: "أبدأ مشروعك الآن",
        ourMessage: "مرحبا بك في موقع خدمتي إنضم الينا الان للاستمتاع بافضل تجربه للعمل الحر لرواد الاعمال علي المنصات العربيه",
        enterProjectTitle: "أدخل عنوان العمل",
        startNow2: "إبدأ الآن",
        homeText1: ` تستطيع علي خدمتي ان تنفذ مشروعك بمنتهي السهوله ، فقط قم بوصفه جيدا فخدمتي يوفر لعملائه مساحه واسعه للتحديد ما يريدونه بدقه ومن ثم قم بنشره ليصل الي امهر الفنيين في المجال المطلوب.`,
        homeText2: ` ستتلقي الكثير من العروض في هذه المرحله ، نحن سنحرص علي مساعدتك علي اختيار من هم انسب لتنفيذ مشروعك`,
        homeText3: `خدمتي يوفر لك افضل الطرق للدفع واكثرها التزاما وامانا ، احرص علي تقييم العميل بحياديه للمساهمه في تطوير العمل الحر .`,
        // about *************************
        whyWeAreGood: "لماذا نحن متميزون",
        about_projects: "معرض للمنتجات",
        about_getYorDreams: "حقق طموحك",
        about_rate: "إرتق في التقييم",
        about_trading: "سهولة في التداول",
        contactUs: "إتصل بنا",
        about_projectsDetails: "قم بعرض منتجاتك في خطوه واحده.",
        about_getYorDreamsDetails: "قم بإضافه خدماتك و مهاراتك للتعريف عن نفسك بطريقه احترافيه",
        about_rateDetails: "احرص علي الحصول علي تقييم ايجابي في كل مره.",
        about_tradingDetails: "ختر نوع العمله وقم بتحويل اموالك في منتهي السهوله والامان.",
        contactUsDetails: "تواصل معنا بسهوله لتلقي مشكلاتك وأقتراحاتك",

        // Categories
        relatedTo: "التصنيفات الخاصة بـ ",


        // Projects


        // Project Details





        noEmptyField:"You cannot leave a field blank",

        getingOffers:  "The stage of receiving offers",
        executing:  "Implementation phase",
        delivering:  "Delivery Phase",
        projectFinished:  "Congratulations on completing your work.",
        finishedOk:  "Done by God's goodness",
        isFinished:  "Achieved",
        projectCard:  "Project Card",
        price:  "budget",
        finishTime:  "Duration of Execution",
        offersAvg:  "Average Shows",
        offersCount:  "Number of Shows",
        pOwner:  "Owner of the project",
        pDetails:  "Project Details",
        attachment:  "attached files",
        comment:  "comment",
        time:  "Time",
        day:  "day",
        userMoney:  "Your dues",
        addComment:  "Add your comment here",
        add:  "Add",
        addNew: "Add New",
        canNotAddOffers:  "You cannot add offers",
        pOffersDisabled:  "Receipt of offers has been disabled",

        // CategoryList 
        category: 'التصنيف',
        categoryHighPrice: "سقف الميزانية",

        // Otheres 
        showDetails: "عرض التفاصيل",
        showMore: "عرض المزيد",
        mostUsed: "الأكثر شيوعاً",
        next: "السابق",
        prev: "التالي",
        more: "المزيد",

        // Services
        mustRate: "يتوجب عليك إضافة تقييم",
        rateService: "تقييم الخدمة",
        addServiceRate: "أضف تقييمك للخدمة",
        rate: "التقييم",

        // addToCart
        addToCart: "اضف إلى السلة",
        wilAddToCart: "سوف يتم إضافة الخدمة إلى السلة",
        removeFromCart: "حذف من السلة",
        willRemoveFromCart: "سوف يتم حذف الخدمة من السلة",
        cartCount: "حدد عدد مرات التنفيذ",
        doYouWantToContinue: "هل تريد اﻹستمرار؟",
        agree: "موافق",
        redo: "تراجع",

        // Header Title 
        sort: "الترتيب",
        modified: "الاحدث",
        maxPrice: "الأعلى سعراً",
        minPrice: "الأقل سعراً",

        // Errors 
        error: "Sorry an error occurred",
        error_containPhoneOrEmail: "The text cannot contain a phone number or an email",
        error_tryAgain: "An error occurred, please try again",
        error_canNot: "Sorry, the request could not be made",
    
        permissionError: 'Server connection error, make sure you are connected to the Internet',
        connectionError: "Server connection error, make sure you are connected to the Internet",
        notFoundReq: 'Request not found',
        noData: "No data to display",
    
        // Connection
        connecting: "Connecting",
    
        // Login
        loginWelcome: "Welcome back! Please login to continue",
        email: "Email",
        password: "Password",
        forgetPassword: "I forgot my password",
        change: "Change",
        checked: "Data Verified",
        inValidLogin: "Email or password wrong",
        login: "Login",
    
        // Table
        accept: "Save",
        total: "tossed",
        itemWillDelete: 'This item will be removed from the system',
        doYouWantToContinu: "Do you want to continue?",
        editData: "edit data",
    
        // Tr
        edit: "Edit",
        delete: "Delete",


        goBack: "Go Back",
        "404": "Page Not Found",
        dataSaved: 'Data Saved',

    }
}