import React from 'react'
import numberPro from '../../pro/numberPro';

export const FooterData = (props) => {
    let th = props.data;

    let count = props.count;




    let pages = count / props.rowOfPage;


    // let modelDet = props.modelDet

    console.log(111);
    // let cut = props.cut ? props.cut : []; // العناصر المراد إقتصاصها من العرض


    console.log(props.rowsTotals);
    let pageTotals = ''
    let rowsTotals = ''
    if (Object.keys(props.rowsTotals).length > 0) {
        rowsTotals = props.data.map((data, i) => {

            if (data.showInRep === 2)
                return <></>

            let value = '-'

            if (props.rowsTotals[data.itemID])
                value = props.rowsTotals[data.itemID]

            if (data.type === 'countable')
                value = numberPro.dotted(value)
            return (

                <th key={i}> <nobr>{value} </nobr></th>
            )
        }
        );
    }
    if (Object.keys(props.pageTotals).length > 0 && pages > 1) {
        pageTotals = props.data.map((data, i) => {

            if (data.showInRep === 2)
                return <></>

            let value = '-'

            if (props.pageTotals[data.itemID])
                value = props.pageTotals[data.itemID]

                if (data.type === 'countable')
                value = numberPro.dotted(value)
            return (

                <th key={i}> <nobr>{value} </nobr></th>
            )
        }
        );
    }

    // let colSpan = 1;


    // if (props.renderEditBtn) {
    //     colSpan += 1
    // }
    // if (props.hasShowAction || props.action) {
    //     colSpan += 1
  
    // }
    // let formDetailsTdTag = <></>
    // if (formDetailsTd === 2)
    //     formDetailsTdTag = <td></td>
    // let modelDetTag = <></>
    // if (modelDet === 2)
    //     modelDetTag = <td></td>
    if (props.action) {
        return <>
            {
                pageTotals &&
                <tr>
                    {pageTotals}


                    <td>مجموع الصفحة</td>

                </tr>
            }
            {
                rowsTotals &&
                <tr>
                    {rowsTotals}


                    <td> المجموع الكلي</td>

                </tr>
            }
        </>
    }
    return (
        <tfoot>
            {
                pageTotals &&
                <tr>

                    {pageTotals}
                    <td>مجموع الصفحة</td>
                </tr>

            }
            {
                rowsTotals &&
                <tr>

                    {rowsTotals}
                    <td> المجموع الكلي</td>

                </tr>

            }

        </tfoot>
    )
}

