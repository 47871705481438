import { loginTypes, dbTypes, appTypes } from "./actionTypes";
import URL, { TOKEN_TYPE, ACCESS_TOKEN, TOKEN_USER, SUBFOLDER } from "../../local/config";
import Axios from "axios";



export const logUserIn = (userDetails) => dispatch => {

    dispatch({ type: loginTypes.success, value: userDetails });

}
export const isLogedIn = (user) => {
    return {
        type: loginTypes.islogedIn,
        value: user
    }
}
export const checkLoggedIn = () => async dispatch => {
    dispatch({ type: loginTypes.checkLoggedIn });
    let accessToken = await localStorage.getItem(ACCESS_TOKEN);

    if (accessToken === null || accessToken === "") {
        dispatch({ type: loginTypes.notLoggedIn });
    } else {
        // ToastAndroid.show("جاري تسجيل الدخول..", ToastAndroid.SHORT);
        await Hastoken(dispatch);
    }
}
// export const Login = () => async dispatch => {

//     dispatch({ type: loginTypes.success });
//     let accessToken = await localStorage.getItem(ACCESS_TOKEN);

//     if (accessToken === null || accessToken === "") {
//         doFacebookLogin(dispatch);
//     } else {
//         // ToastAndroid.show("جاري تسجيل الدخول..", ToastAndroid.SHORT);
//         Hastoken(dispatch);
//     }
//     // doFacebookLogin(dispatch);

// }

// const saveUserData = async (user, type, accessToken, dispatch) => {

//     let data = JSON.stringify({
//         user,
//         accessToken
//     });

//     let res = await axios.post(`${URL}/login/${type}`, { data: data }).then(res => res)
//         .catch((error) => 'error');
//     if (res === 'error') {

//         // localStorage.show("لم يتم حفظ بيانات تسجيل الدخول", localStorage.SHORT);

//     } else {
//         if (res.status === 200) {
//             res = res.data
//             if (res.error === false) {
//                 user = res.user;
//                 accessToken = res.accessToken;

//                 LoginDone({ user, accessToken, type }, dispatch);

//             } else {
//                 dispatch({ type: loginTypes.error });
//             }

//         } else {
//             AlertError("الرجاء التحقق من إتصالك بالانترنت");
//         }
//     }
// }
// // عرض شاشة تسجيل الدخول 

export const LoginWithEmail = (userDetails) => dispatch => {
    let { user, accessToken, type } = userDetails;
    let user1 = user;
    user = JSON.stringify(user);

    accessToken = `${accessToken}`;
    type = btoa(type);
    // user = new TextEncoder('utf-8').encode(user);
    // user = JSON.stringify(user);    

    // accessToken = new TextEncoder('utf-8').encode(accessToken);
    // type = new TextEncoder('utf-8').encode(type);

    localStorage.setItem(TOKEN_USER, user);
    localStorage.setItem(TOKEN_TYPE, type);
    localStorage.setItem(ACCESS_TOKEN, accessToken);

    dispatch({ type: loginTypes.loginDone, value: { user: user1, accessToken, loginType: type } });
}
export const LoginDone = (userDetails, dispatch) => {

    let { user, accessToken, type } = userDetails;

    localStorage.setItem(TOKEN_USER, user);
    localStorage.setItem(TOKEN_TYPE, type);
    localStorage.setItem(ACCESS_TOKEN, accessToken);

    dispatch({ type: loginTypes.loginDone, value: { user, accessToken, loginType: type } });
}
export const Hastoken = async (dispatch) => {
    let user = await localStorage.getItem(TOKEN_USER);
    let loginType = await localStorage.getItem(TOKEN_TYPE);
    let accessToken = await localStorage.getItem(ACCESS_TOKEN);
    user = JSON.parse(user);


    
    let res = await Axios.get(`${URL}/g/checkSession/${accessToken}`).then(res => res)
        .catch((error) => 'error');
    if (res === 'error') {

    } else {
        res = res.data
        
        if (res.error === true) {
            localStorage.removeItem(TOKEN_USER);
            localStorage.removeItem(TOKEN_TYPE);
            localStorage.removeItem(ACCESS_TOKEN);
            dispatch({
                type: loginTypes.notLoggedIn,
                value: {
                    isLoggedIn: false
                }
            })
        } else {
            let session = res.result;
            let tableDetails = res.tableDetails;
            // alert(JSON.stringify(tableDetails))
            dispatch({ type: appTypes.setTableDetails, values: { tableDetails } });
            if (session.sessStatus === 2 || session.sessStatus === 3) {
                dispatch({
                    type: loginTypes.notLoggedIn
                })
            } else {
                user = res.user;
                dispatch({
                    type: loginTypes.setTokens, value: {
                        user,
                        loginType,
                        accessToken
                    }
                });
                if (res.menuLinks) {
                    dispatch({
                        type: dbTypes.setMenuLinks, values: {
                            menuLinks: res.menuLinks
                        }
                    });
                }
            }
        }

    }
    // loginType = atob(loginType);

    // // user = JSON.parse(user);
    // dispatch({
    //     type: loginTypes.loginDone, value: {
    //         user,
    //         loginType,
    //         accessToken
    //     }
    // });
}

export const LoginType = (type, dispatch) => {
    dispatch({ type: loginTypes.loginType, value: { loginType: type } });
}

export const LogOut = (type) => async  dispatch => {
    let error = false;
    // dispatch({type: loginTypes.loading});

    if (type === "phone") {

    } else if (type === "google") {
        try {
            // await GoogleSignin.revokeAccess();
            // await GoogleSignin.signOut();
        } catch (error) {
            // error = 'true';
        }
    } else if (type === "facebook") {
        try {
            // await LoginManager.logOut();
        } catch (error) {
            // error = 'true';
        }
    }

    if (!error) {
        await localStorage.removeItem(ACCESS_TOKEN);
        await localStorage.removeItem(ACCESS_TOKEN);
        await localStorage.removeItem(TOKEN_TYPE);
        await localStorage.clear();

        dispatch({ type: loginTypes.logOut });
        window.location = SUBFOLDER
    }
}

// b410f8e3c7d17f499f57fc052f777c3b
// b410f8e3c7d17f499f57fc052f777c3b