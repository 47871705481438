import React from "react";
import nl2br from 'react-nl2br';
import axios from "axios";
import URL, { TOKEN_NAME, GUEST_NAME, SITE_NAME, logMsg } from "../app/local/config";
import Footer from "../component/Footer";
import History from "../app/tools/History";
import { string } from "../app/lang";

class Base extends React.Component {
    constructor(props) {
        super(props);

        this.footer = <Footer dd />
        this.brand = SITE_NAME
        this.url = URL;
        this.TOKEN_VALUE = 404;
        // this.tables = tables
        this.token_name = TOKEN_NAME;
        this.guest_name = GUEST_NAME;
        this.title = string("homePage");

        this.pageTitle = string("appName") + " | ";
        this.isCanceled = true;
        this.changeState = this.changeState.bind(this);
        this.emptyStatus = this.emptyStatus.bind(this);

        this.state = {
            status: "",
            connected: false,
            insert: {},
            edit: {},
            deleted: {},
            file: {},
            table: [],
            c_status: "",
            upload: {},
            PAGE_ERROR: "",
            validUser: "",
            tableDetails: {},
            TOKEN_VALUE: 404
        }


    }

    show = (str) => {
        alert(JSON.stringify(str))
    }
    setUrl = async (condText, page) => {


        this.props.history.push(page + '?' + condText)

    }
    async getToken() {
        this.TOKEN_VALUE = localStorage.getItem(this.token_name) ? localStorage.getItem(this.token_name) : 404;
    }
    emptyStatus() {
        this.changeState("status", "");
    }
    componentWillUnmount() {
        this.isCanceled = true;
    }
    UNSAFE_componentWillMount() {
        this.getToken()
    }
    componentDidMount() {
        document.getElementById("pageTitle").innerHTML = this.pageTitle + " " + this.title;
        this.isCanceled = false;
        // this.getToken();
        this.setState({ TOKEN_VALUE: this.TOKEN_VALUE })
    }

    changeState(state, value) {
        if (!this.isCanceled) {
            this.setState({ [state]: value });
        }
    }

    api = async (type, url, fields = {}) => {
        url = this.url + "/" + url

        let accessToken = this.props.accessToken;
        if (!accessToken) {
            alert(string("error"));
            return { error: true, errorType: "userValidation" }
        }
        fields = { fields, accessToken }
        let res = await axios[type](`${url}`, fields)
            .catch((error) => 'error');

        if (res === 'error') {
            this.changeState("connectionStatus", "error");
            this.changeState('PAGE_ERROR', string("connectionError"));
            return { error: true, errorType: "connectionError" }
        } else {
            if (res.status === 304 || res.status === 200 || res.statusText === "OK") {
                res = res.data;

                console.log(333);
                if (!res.error) {
                    if (res.result) {
                        console.log(res.result);
                        this.changeState("table", res.result)
                        this.changeState("resultDetails", res.resultDetails);

                    }
                    this.changeState("connectionStatus", "connected");
                    this.changeState('PAGE_ERROR', '');
                    this.changeState("validUser", true);
                    this.changeState("validUser", true);
                    this.changeState("connected", true);
                } else {
                    this.changeState("connectionStatus", "connected");
                    this.changeState('PAGE_ERROR', res.errorType);

                }
                let data = res;
                return (data)
            } else {
                return { error: true, errorType: "connectionError" }
            }
        }
    }

    get = async () => {
        let page = this.page;
        let url = `${this.url}/g/token/${this.TOKEN_VALUE}/page/${page}/f/none/`;

        let response = await fetch(url)

            .catch(err => 'noConnection');

        if (response === 'noConnection') {
            this.changeState("c_status", "error");
            this.changeState('PAGE_ERROR', string("connectionError"));
            return;
        }
        let { status, ok, statusText } = response;
        if (status === 200 && statusText === "OK" && ok === true) {
            response = await response.json();

            if (response.error === false) {
                this.changeState("table", response)
                this.changeState("c_status", "connected");
                this.changeState('PAGE_ERROR', '');
                this.changeState("validUser", true);
            }
            else {
                this.changeState("table", response)
                this.changeState("c_status", "error");
                let error;
                if (response.errorType === 'noData') {
                    error = <div>{string("noData")} </div>
                } else if (response.errorType === 'connectionError') {
                    error = <div> {string("connectionError")} </div>
                } else if (response.errorType === 'error') {
                    error = <div>Test Error</div>
                }
                this.changeState('PAGE_ERROR', error);
            }
        } else if (status === 404) {
            this.changeState("c_status", "error");
            this.changeState('PAGE_ERROR', string("notFoundReq"));
        }
    }

    Loading = () => {
        return <div align="center" style={{ width: "100%" }}><img src={require("../images/loading.gif")} alt={string("connecting")} width={40} /></div>
    }

    mLoading = () => {
        return <div ></div>
    }

    nl2br = (str) => {
        return nl2br(str)
    }

    /**
     * db query functions 
     */

    do = async (action, dbUrl, fields = {}, id = {}) => {
        // this.changeState("c_status", "connecting");
        let data = JSON.stringify({
            fields: fields,
            id: id
        });
        if (action === 'deleted') {
            data = JSON.stringify({
                fields: { deleted: true },
                id: fields
            });
        }
        fields = JSON.stringify(id)
        const $this = this;
        let res = await axios.post(`${this.url}/${dbUrl}`, { data: data })
            .catch((error) => 'error');
        if (res === 'error') {
            $this.changeState("c_status", "error");
            $this.changeState('PAGE_ERROR', string("connectionError"));
        } else {
            if (res.status === 200 && res.statusText === "OK") {
                res = res.data;

                let insertRes = {}
                let { error, errorType } = res;

                if (error === false) {
                    insertRes.error = false;
                    this.changeState("validUser", true);
                    console.log('one');
                } else if (error === true) {
                    insertRes.error = true;

                    insertRes.errorType = errorType;
                    if (res.validationError) {
                        let fields = this.state.fields;

                   
                        // this.changeState('fields', fileds)
                        for (let index in fields) {
                            let id = fields[index].itemID
                            if (res.fieldErrors[id] !== undefined) {
                                fields[index].status = res.fieldErrors[id];
                            }
                        }
                    }

                }
                this.changeState("c_status", "connected");
                this.changeState(action, insertRes)
            } else if (res.status === 404) {
                this.changeState("c_status", 404);
            } else {
                this.changeState("c_status", "connectionError");
            }
        }

    }
    insertItem = async (page, fields = { username: 3 }) => {
        // page = this.getPageName(page);

        await this.do("insert", `insert/token/${this.props.accessToken}/page/${page}/f/none`, fields)

    }

    editItem = async (page, fields, key) => {
        // page = this.getPageName(page);

        let url = `update/token/${this.props.accessToken}/page/${page}/f/none`;
        await this.do("edit", url, fields, key)
    }
    deleteItem = async (page, id) => {
        // page = this.getPageName(page);
       
        await this.do('deleted', `deleteItem/token/${this.props.accessToken}/page/${page}/f/none`, id);
    }



    //==========================================
    //=================================== Helper
    getPageName = (page) => {
        if (page !== '') {
            return page
        }
    }

    cn = (msg = "", data) => {

        if (logMsg === 1) {
            if (msg !== "") {
                msg = msg + ': '
            }
        }

    }

    upload = async (page, type = "new", fields = {}, image) => {
        const formData = new FormData();

        formData.append('image', image);
        formData.append('fields', JSON.stringify(fields));
        formData.append("accessToken", this.props.accessToken);

        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        };
        console.log(1111111);
        console.log(image);

        let response = await axios.post(this.url + `/upload/type/${type}/${page}`, formData, config)
            .catch((error) => "error");

        if (response === "error") {

        } else {
            response = response.data
            let uploadDetails = { error: false }
            if (response.error === false) {
                uploadDetails = { error: false, newRow: response.newRow }
            }
            this.changeState("upload", uploadDetails)
        }
    }
    _find = async (details) => {
     
        let error;
        // if (!this.tables[this.tableName]) {
        //     this.changeState("c_status", "error");
        //     this.changeState('PAGE_ERROR', "حدث خطأ في غير متوقع في الإتصال");
        //     return;
        // }
        let { type } = details
        let order = null;
        if (details.order) {
            order = details.order
        }

        let page = this.page;
        if (!this.props.accessToken) {
            this.changeState('PAGE_ERROR', "LoginError");
            this.changeState("c_status", "error");
            return;
        }

        let url = `${this.url}/find/${type}/token/${this.props.accessToken}/page/${page}/f/none/`;
        let response = await axios.post(url, { cond: this.conditions, order })
            .catch((error) => 'error');


        if (response === 'error' || response === undefined) {
            this.changeState("c_status", "error");
            this.changeState('PAGE_ERROR', string("connectionError"));
        }

        if (response === 'noConnection') {
            this.changeState("c_status", "error");
            this.changeState('PAGE_ERROR', string("connectionError"));

            return;
        }

        let { status } = response;
        if (response.status === 200) {
            
            response = response.data;

            console.log(response);
            if (response.error === false) {
              

                await this.changeState("table", response.result)
                this.changeState("tableDetails", response.tableDetails)
                this.changeState("c_status", "connected");
                this.changeState('PAGE_ERROR', '');
                this.changeState("validUser", true);
            }
            else {
                this.changeState("table", []);
                this.changeState("c_status", "error");
                this.changeState("validUser", response.validUser);
                this.changeState('PAGE_ERROR', response.errorType);
                this.changeState("tableDetails", response.tableDetails)

            }
        } else if (status === 404) {
            this.changeState("c_status", error);
            this.changeState('PAGE_ERROR', string("notFoundReq"));
        }
    }
    //end find queries
    setFilttersForm =(data)=>{

    }
    findAll = (order = null) => {

   
        return this._find({ type: "all", order });

    }

    findOne = () => {
        return this._find({ type: "one" });
    }

    findFrom = (table) => {
        let page2 = this.getPageName(table);

        return this._find({ type: `from/${page2}` });
    }
    findGroup = (table) => {
        let page2 = this.getPageName(table);
        return this._find({ type: `group/${page2}` });
    }

    findAdvert = () => {
        return this._find({ type: "advert" });
    }

    // Utils 
    queryString = () => {
        let queryObj = {};
        this.queryString = new URLSearchParams(this.props.location.search);
        for (let p of this.queryString) {
            queryObj[p[0]] = p[1];
        }
        return queryObj
    }

    // Utils 
    queryString3 = (search) => {
        let queryObj = {};
        this.queryString = new URLSearchParams(search);
        for (let p of this.queryString) {
            queryObj[p[0]] = p[1];
        }
        return queryObj
    }

    goBack = () => {
        this.props.history.goBack()
    }

    queryString2 = () => {
        let queryObj = {};
        if (History.location) {
            let qs = new URLSearchParams(History.location.search);
            for (let p of qs) {
                queryObj[p[0]] = p[1];
            }
            return queryObj
        }
    }




    
}




export default Base;