import React from 'react'
import { Redirect } from 'react-router-dom'
import { ACCESS_TOKEN } from '../app/local/config'
import { connect as connectTo } from 'react-redux'
import Page from '../pro/Page';
import Base from '../pro/Base';
import { string } from '../app/lang';

class Connect extends Base {

    check() {
        let token = localStorage.getItem(this.token_name) ? localStorage.getItem(this.token_name) : 404;
        if (token !== 404) {
            this.props.changeLoginState(true);
        }
    }
    componentDidMount = async () => {
        super.componentDidMount();
    }

    render() {
        let { c_status, PAGE_ERROR, validUser } = this.props.parent.state;

        let pageData = <this.Loading />;
        let data = pageData;
        let cState = 1;
        let error = false;

        if (this.props.parent.state.c_status === 'connecting') {
            pageData = <this.Loading />
        } else if (c_status === 'error') {
            if (validUser === false) {
                localStorage.removeItem(ACCESS_TOKEN);
                // pageData = <div style={styles.error}>خطأ في تسجيل الدخول</div>
                alert("خطأ في تسجيل الدخول")
                error = true;
                return <Redirect to='/' />
            } else if (PAGE_ERROR === "noData") {
                pageData = <div className="alert alert-danger">
                    {this.props.children}
                    <h3 style={{ textAlign: "center" }} >{string("noData")}</h3>
                    {/* {this.props.parent.state.PAGE_ERROR} */}
                </div>;
                // return <Redirect to='/login' />
            } else if (PAGE_ERROR === "LoginError") {
                // pageData = <div>الرجاء تسجيل الدخول</div>
                return <Redirect to='/' />
            } else {
                pageData = <div style={styles.error}>{PAGE_ERROR}</div>
                error = true;
            }
        } else if (c_status === 'connected' && error === false) {
            pageData = this.props.children;
            error = false;
            cState = 2;
        }
        if (error === true && cState === 1) {
            data = <div style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "white" }}>
                {pageData}
            </div>
        } else {
            data = pageData;
        }
        return (
            <React.Fragment>
                <Page>
                    {data}
                </Page>
            </React.Fragment>
        )
    }
}

const styles = {
    error: {
        color: "#008080"
    }
}
// const Connect = (props) => {

// }

const stateToProps = (state) => {
    return {
        hasLoggedIn: state.hasLoggedIn
    }
}

export default connectTo(stateToProps, {})(Connect);