import React from "react";

import URL, { TOKEN_NAME, GUEST_NAME, SITE_NAME } from "../app/local/config";
import Footer from "../component/Footer";
import Base from "./Base";
import { string } from "../app/lang";


class Route extends Base {
    constructor(props) {
        super(props);

        this.footer = <Footer dd />
        this.brand = SITE_NAME
        this.url = URL;
        this.TOKEN_VALUE = 404;
        // this.tables = tables
        this.token_name = TOKEN_NAME;
        this.guest_name = GUEST_NAME;
        this.title = string("homePage");
        this.pageTitle = string("appName") + " | ";
        this.isCanceled = true;


        this.state = {
            status: "",
            connected: false,
            insert: {},
            edit: {},
            deleted: {},
            file: {},
            table: [],
            c_status: "",
            upload: {},
            PAGE_ERROR: "",
            validUser: "",

            TOKEN_VALUE: 404
        }


    }
    async getToken() {
        this.TOKEN_VALUE = localStorage.getItem(this.token_name) ? localStorage.getItem(this.token_name) : 404;
    }
    componentDidCatch = () => {

    }
    emptyStatus() {
        this.changeState("status", "");
    }
    componentWillUnmount() {
        this.isCanceled = true;
    }
    UNSAFE_componentWillMount() {
        this.getToken()
    }

    componentDidMount() {
        
        
        document.getElementById("pageTitle").innerHTML = this.pageTitle + " " + this.title;
        this.isCanceled = false;
        // this.getToken();
        this.setState({ TOKEN_VALUE: this.TOKEN_VALUE })
    }

    changeState(state, value) {
        if (!this.isCanceled) {
            this.setState({ [state]: value });
        }
    }
}


export default Route;