import React, { Component } from 'react'
import ReactMapboxGl, { Layer, Feature, } from 'react-mapbox-gl';
import { Modal, Button, Icon } from 'semantic-ui-react';

import "./input.css"
const Map = ReactMapboxGl({
    accessToken:
        "pk.eyJ1Ijoid2RhbGdyYiIsImEiOiJjanRidzY5Y3kwa25tNDRvNW5wZzl2bmthIn0.3tgtoKS0xA5KggPC09I4Gg"
});
export default class MapInput extends Component {
    constructor(props) {
        super(props);;
        let center = this.props.defaultValue ? this.props.defaultValue : "15.59307623529277,32.53250546926847";
        let lat = center.split(",")[0]
        let lng = center.split(",")[1]
        this.state = {


            zoom: 14,
            showMap: false,
            center: `${lat},${lng}`,
            lng,
            lat,
        }
    }

    _getCoords = (data) => {
        let latLng = data.getCenter();
        let zoom = data.getZoom();
        console.log({ latLng });
        let center = `${latLng.lat},${latLng.lng}`
        this.setState({ center, ...latLng, zoom })
    }
    toggleMap = (event) => {
        event.preventDefault();
        this.setState({ showMap: !this.state.showMap })
    }

    selectMap = (event) => {
        event.preventDefault();
        this.props.onSelect(this.state.center);
        this.toggleMap(event);
    }

    render() {
        return (
            <div>
                <Button onClick={this.toggleMap}>
                    <span>فتح الخريطة</span>
                    <Icon name="map marker alternate" color="red" />
                </Button>
                <span > {this.state.center} </span>
                <Modal
                    // dimmer={"blurring"}
                    open={this.state.showMap}

                    onClose={this.toggleMap}>
                    <Modal.Header>تعديل البيانات</Modal.Header>
                    <Modal.Content>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                            <div style={{ width: "80%", height: 400, }}>

                                <Map
                                    dir="rtl"
                                    Style="mapbox://styles/mapbox/streets-v11"
                                    containerStyle={{
                                        height: '100%',
                                        width: '100%'
                                    }}
                                    ref
                                    center={{ lat: this.state.lat, lng: this.state.lng, }}
                                    zoom={[this.state.zoom]}
                                    onDragEnd={this._getCoords}
                                >

                                    <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
                                        <Feature coordinates={[15, 32]} />
                                    </Layer>
                                </Map>
                                <div className="marker-wrapp">
                                    <Icon name="map marker alternate" color="red"
                                        style={{ fontSize: 40 }}
                                    />

                                </div>
                            </div>
                        </div>
                    </Modal.Content>
                    <Modal.Actions style={{ textAlign: "center" }}>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='left'
                            content="حفظ"
                            onClick={this.selectMap}
                        />
                        <Button color='black' onClick={this.toggleMap}>
                            <span>تراجع</span>
                        </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}
