import React from 'react'
import { connect } from 'react-redux'
import Base from '../../pro/Base';
import { string } from '../lang';





class Profile extends Base {
    constructor(props) {
        super(props);
        this.title = "الملف السخصي"
        this.isOwner = false;
        this.state = {
            ...this.state,
            errorStatus: "",
            loading: true,
            email: "",
            messageHasSent: false,
            user: { webSites: [], skills: [], image: { url: this.props.user.image } }
        }
        this.user = this.props.user;
        this.query = this.queryString();
        this.profileUser = this.props.match.params.userId * 1

        if (this.profileUser === this.user.id) {
            this.isOwner = true;
        }

    }
    componentDidMount() {
        super.componentDidMount()
    }
    _forgetPassword = async (event) => {
        event.preventDefault();
        this.changeState("status", "")
        this.changeState("messageHasSent", false)


        // this.connecting();
        // let query = this.queryString();
        let email = this._email.value;
        let isValidEmail = new RegExp('[a-z0-9]+@[a-z]+\\.[a-z]+', 'i').exec(email);
        if (isValidEmail) {
            if (isValidEmail) {
                let url = "users/forget"
                let fields = {
                    email,
                }

                let api = await this.api("post", url, fields);
                if (api.error) {
                    this.changeState("status", "الرجاء التأكد من بريدك اﻹلكتروني")

                } else {
                    // this.changeState("status", "تم إرسال رابط إلى عنوان البريد اﻹلكترون، قم بإستخدامه لتغيير كلمة المرور")
                    this.changeState("messageHasSent", true)
                    this.changeState("email", email)
                    this.changeState("loading", false)
                }

                // this.connected();
            }
        } else {
            this.changeState("status", "البريد غير صالح")
        }

    }

    render() {

        return (
            <div noFooter>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {
                        !this.state.messageHasSent ?
                            <div class="login-parent body">
                                <div class="form-wrap">
                                    <div class="form">
                                        <h2>
                                            <img src={require('../../images/logo.png')} alt="" />
                                        </h2>
                                        <h4>{string('forgetPassword')}</h4>
                                        <div style={{ color: "red", textAlign: 'center', marginBottom: 10 }} ref="status" className="error-status">{this.state.status}
                                        </div>
                                        <form onSubmit={this.loginUser}>
                                            <div class="field">
                                                <i class="fal fa-envelope "></i>
                                                <input
                                                    type="text"
                                                    name=""
                                                    id=""
                                                    placeholder={string('email')}
                                                    ref={(ref) => this._email = ref}
                                                    onChange={() => this.emptyStatus()}
                                                />
                                            </div>

                                            <div class="lower">

                                            </div>

                                            <div class="text-center">

                                                <a href="/" onClick={this._forgetPassword}><button className="" style={{ background: "transparent", border: "transparent" }}>
                                                    {string("change")}
                                                     </button></a>

                                            </div>
                                        </form>
                                    </div>
                                    <div class="text-padding">
                                        {/* <a href="sign.html" class="new">هل انت جديد؟<span> سجل الان</span></a> */}
                                    </div>
                                </div>
                            </div>
                            // <form className="col-md-4" style={{ padding: "20px" }}>
                            //     <div ref={ref => this._status = ref} style={{ padding: 10, textAlign: "center", color: "red", fontWeight: "bold" }}>{this.state.status}</div>
                            //     <div className="form-group">
                            //         <input placeholder="أدخل البريد اﻹلكتروني" className="form-control" ref={ref => this._email = ref} />
                            //     </div>
                            //     <Button className="btn-block" color="blue" onClick={this._forgetPassword}>
                            //         إستعادة كلمة المرور
                            // <Icon name="accessible" />
                            //     </Button>
                            // </form>
                            :

                            <div className="col-md-6" style={{ padding: "20px", textAlign: 'center' }}>
                                <h3 className="alert alert-success">تم إرسال رابط إلى
                                (<strong style={{ color: "black" }}>{this.state.email}</strong>) <br />
                                    قم بإستخدامه لتغيير كلمة المرور
                                    </h3>
                            </div>
                    }
                </div>
            </div >
        )
    }
}



const stateToProps = (state) => {

    return {
        isLoggedIn: state.auth.isLoggedIn,
        accessToken: state.auth.accessToken,
        user: state.auth.user,
    }
}

export default connect(stateToProps, {})(Profile);
