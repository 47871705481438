import React from 'react'

export const Th = (props) => {
    let th = props.data;

    let formDetailsTd = props.formDetailsTd
    let modelDet = props.modelDet
    // let cut = props.cut ? props.cut : []; // العناصر المراد إقتصاصها من العرض

    if (th) {
        th = props.data.map((data, i) => {

            if (data.showInRep === 2)
                return <></>
            return (

                <th key={i}> <nobr>{data.label} </nobr></th>
            )
        }
        );
    }

    let action = <th></th>;
    let colSpan = 1;


    if (props.renderEditBtn) {
        colSpan += 1
    }
    if (props.hasShowAction || props.action) {
        colSpan += 1
        action = <th colSpan={colSpan}></th>;
    }
    let formDetailsTdTag = <></>
    if (formDetailsTd === 2)
        formDetailsTdTag = <td></td>
    let modelDetTag = <></>
    if (modelDet === 2)
        modelDetTag = <td></td>
    if (props.action) {
        return <tr>
            {th}
            {action}
            {formDetailsTdTag}
            {modelDetTag}
        </tr>
    }
    return (
        <tr>
            {th}
        </tr>
    )
}

