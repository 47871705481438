import React from 'react'
import './actionMenu.css';
import { Dropdown, Icon } from 'semantic-ui-react';
export default (props) => {

    let menuIcon = <svg width="20" height="20" viewBox="0 0 24 24" className="NSy2Hd RTiFqe P5kiYb"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>
    if (props.icon) {
        if (props.icon !== "default") {
            menuIcon = <Icon name={props.icon} />
        }
    }
    return (
        <Dropdown icon={
            menuIcon
        } floating labeled button >

            <Dropdown.Menu>
                {
                    props.links.map((link, i) => <Dropdown.Item key={i} onClick={link.onClick} style={{ display: "flex", }}>
                        <Icon name={link.icon} style={{ color: link.color }} />
                        <div style={{
                            flex: 1,
                            padding: "0px 10px"
                        }}>{link.title}</div>
                    </Dropdown.Item>)
                }
            </Dropdown.Menu>
        </Dropdown >
    )
}
