import React from "react";

import Base from "../pro/Base";
import { string } from "../app/lang";

class Nav extends Base {
  constructor(props) {
    super(props)
    this.error = false;
  }
  toggleMenuBar = () => {
    var sidebarParent = document.getElementById('expand-shrik-sidebar');
    var sideBarLinksItems = document.getElementById('sideBarLinksItems');
    // console.log(sidebarParent.classList.contains('shrink-sidebar'));
    if (sidebarParent.style.width === '200px') {

      sidebarParent.style.width = '64px';
      sideBarLinksItems.style.width = '64px';
      // sideBarLinksItems.style.display = 'block';
      sideBarLinksItems.className = 'menu-hide';
      sidebarParent.classList.add('shrink-sidebar');

    } else {
      sidebarParent.classList.remove('shrink-sidebar');

      sidebarParent.style.width = '200px';
      sideBarLinksItems.style.width = '200px';
      sideBarLinksItems.className = 'menu-show';
    }

  }
  render() {

    return (
      <>
        <div className="content" style={{ background: "#eeeeee", paddingTop: 70 }}>
          <div className="topbar " style={{ padding: 0, zIndex: 100, position: "fixed", top: 0 }}>
            <div className="dropdown" style={{ display: "none" }} id="dropdown">
              <ul>
                <li>
                  <i className="fal fa-user"></i> البروفايل
                </li>
                <li>
                  <i className="fal fa-home"></i> اخر
                </li>
                <li>
                  <i className="fal fa-cog"></i> اخر
                </li>
                <li>
                  <i className="fal fa-trash"></i> اخر
                </li>
              </ul>
            </div>

            <div className="noti-dropdown" style={{ display: "none" }} id="noti-dropdown">
              <ul>
                <li>
                  هنا تكون الاشعارات
                </li>
                <li>
                  هنا تكون الاشعارات
                </li>
                <li>
                  هنا تكون الاشعارات
                </li>
              </ul>
            </div>
            {/* <div className="upper-content">

              <div className="account" style={{ height: 80 }}>

                <div className="person" >

                  <div className="avatar" onclick="toggleProfileList()">
                    <img src={require('../images/logo.png')} alt="" />
                  </div>
                </div>
              </div>
               <!-- onclick={"toggleProfileList()"}-->

            </div> */}
            <div className="title d-flex" style={{ background: "#17284d", padding: 8 }}>
                <div className='sidenavtop sidenavtop2'>
                  <div className='lines' onClick={this.toggleMenuBar}>
                    <div className='line line-1'></div>
                    <div className='line line-2'></div>
                    <div className='line line-3'></div>
                  </div>

                  <h2 className='sidebar-title'>-</h2>
                </div>
              <h2 style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className="avatara" onClick={() => this.toggleMenuBar()}>
                  <img src={require('../images/logo.png')} alt="" style={{ height: 50, padding: "0px 15px" }} />
                </div>
                <span style={{ textDecoration: "none", color: "white" }}>
                  {string("appName")}
                </span>

              </h2>

              {
                this.props.subTitle &&
                <h3>
                  <i className="fal fa-home"></i>
                  {this.props.subTitle}
                </h3>
              }
            </div>
          </div>

          {/* <div className="cards-conrtainer" > */}
          {this.props.children}
          {/* </div> */}

        </div>

      </>
    );
  }
}

export default Nav;