import React from 'react'
import { Tr } from './Tr';


/**
 * create table rows
 * @param {tr, cut} props 
 * @param tr => table rows
 * @param cut => unvisible table data
 */
export const Rows = (props) => {
    let cut = props.cut;

    let onClick = false;
    if (props.hasShowAction) {
        onClick = props.hasShowAction
    }
    
if(props.tr[0] ==='noData')
  return <></>
    return props.tr.map((data, i) => <Tr
    {...props}
        showInRep={props.showInRep}
        data={data}
        key={i}
        selectedData={props.selectedData}
        footer={props.footer}
        customeBtn={props.customeBtn}
        index={i} 
        cut={cut}
        renderCustomeAction={props.renderCustomeAction}
        pk={props.pk}
        actions={props.actions}
        parent={props.parent}
        page={props.page}
        onClick={onClick}
        getUpdateData={props.getUpdateData}
        getUpdateDataKey={props.getUpdateDataKey}
        getUpdateDataCount={props.getUpdateDataCount}
        tableAther={props.tableAther}
        menuIcon={props.menuIcon}
    />);
}