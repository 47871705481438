import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { string } from './lang';

class NotFound extends Component {

  render() {
    return (
        <div className="App">
        <h2>{string("404")}</h2>
        <Link to="/">{string("goBack")}</Link>
      </div>
    );
  }
}

export default NotFound;
