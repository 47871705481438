import React from 'react'
import { Button } from 'semantic-ui-react';

export const FormSubmit = (props) => {
    let { className, itemID, value } = props;

    return (
        <Button
            onClick={props.onClick}
            itemID={itemID}
            className={className}
            loading={props.loading}
            disabled={props.disabled}
            style={{ marginTop: 17 }}
            color="green"
        >
            <span>{value}</span>
        </Button>
    )
}