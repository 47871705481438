import React from 'react'
import CardItem from './CardItem'
import Base from '../../pro/Base';
import EditForm from '../input/EditForm';


class Cards extends Base {
    constructor(props) {
        super(props)
        this.page = this.props.page
    }

    render() {
        return (
            <div className="row" style={{ margin: 0 }}>
                {
                    this.props.data.map((item, index) => {
                        let title = this.props.fields.title;
                        let content = this.props.fields.content;
                        // let folderType = this.props.item.type
                        let action = this.props.hasShowAction;
                        let clickBtn = false;
                        let queryString = "";

                        if (action) {


                            for (let j in action) {
                                queryString = "";
                                let names = action[j].names;
                                
                                if (action[j].fields)
                                    for (let i = 0; i < action[j].fields.length; i++) {

                                        const field = action[j].fields[i];
                                        if (i === action[j].fields.length - 1) {
                                            queryString += `${names[i]}=${item[field]}`;
                                        } else {
                                            queryString += `${names[i]}=${item[field]}&`;
                                        }
                                    }
                                if (action[j].const) {
                                    for (let item in action[j].const) {
                                        const value = action[j].const[item]
                                        queryString += `&${item}=${value}`
                                    }
                                }


                                // }




                                let _cond = action[j].cond;
                                let _field = action[j].field;
                                if (_cond === '' || item[_field] === _cond)
                                    clickBtn = action[j].page + '?' + queryString




                            }
                        }


                        return <>
                            <CardItem
                                key={`Card_item_${index}`}
                                {...item}
                                image={item.image}
                                title={item[title]}
                                content={item[content]}
                                clickBtn={clickBtn}
                                // folderType = {folderType}
                            >
                                <EditForm
                                    mainParent={this.props.parent}
                                    edit
                                    remove
                                    parent={this}
                                    index={index}
                                    cut={this.props.cut}
                                    values={item}

                                />
                            </CardItem>
                        </>
                    })
                }
            </div>
        )
    }
}

export default Cards;