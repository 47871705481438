import React from 'react'
import SubMenu, {  SlideMenu as SlideMenu2 } from '../../component/SubMenu';
import { connect } from 'react-redux';
import Base from '../../pro/Base';

let list = [

]

class Menu extends Base {
  toggleMenuBar = () => {
    var sidebarParent = document.getElementById('expand-shrik-sidebar');
    var sideBarLinksItems = document.getElementById('sideBarLinksItems');
    // console.log(sidebarParent.classList.contains('shrink-sidebar'));
    if (sidebarParent.style.width === '200px') {

      sidebarParent.style.width = '64px';
      sideBarLinksItems.style.width = '64px';
      // sideBarLinksItems.style.display = 'block';
      sideBarLinksItems.className = 'menu-hide';
      sidebarParent.classList.add('shrink-sidebar');

    } else {
      sidebarParent.classList.remove('shrink-sidebar');

      sidebarParent.style.width = '200px';
      sideBarLinksItems.style.width = '200px';
      sideBarLinksItems.className = 'menu-show';
    }

  } 
  render() {
    let listItems = [

      ...this.props.menuLinks
    ];

    if (this.props.user.id !== 1) {
      // listItems = [
      //   { icon: 'food', title: 'البيانات اﻷساسية', to: 'basic' },
      // ]
    }
    
    return <div className='sidenav shrink-sidebar' id='expand-shrik-sidebar'>
      <div className='links'>
        <div className='sidenavtop'>
          <div className='lines' onClick={this.toggleMenuBar}>
            <div className='line line-1'></div>
            <div className='line line-2'></div>
            <div className='line line-3'></div>
          </div>

          <h2 className='sidebar-title'>-</h2>
        </div>
        <ul id='sideBarLinksItems' className='menu-hide'>
          <SubMenu links={listItems} langQuery={this.props.langQuery}/>

        </ul>
      </div>

    </div >
  }
}

export const SlideMenu = () => {
  return <SlideMenu2 links={list} />
}



const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.auth.isLoggedIn,
    accessToken: state.auth.accessToken,
    user: state.auth.user,
    menuLinks: state.db.menuLinks,
    langQuery: state.app.langQuery,

  }
}
export default connect(mapStateToProps, {})(Menu);